import analyticsWrapper from '@xo-union/tk-analytics';
import {getProductBaseAnalytics} from './getBaseAnalytics';

const trackFulfillment = (isMobile, application, product, guestName, email) => {
  const analytics = getProductBaseAnalytics(isMobile, application, product);
  const {storeName} = product;

  analyticsWrapper.track('Universal Registry Fulfillment Interaction', {
    ...analytics,
    email,
    name: guestName,
    selection: 'fulfill',
    store: storeName,
    userDecisionArea: 'fulfillment modal',
  });
};

export default trackFulfillment;
