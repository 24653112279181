import getStockStatus from '../../components/ProductGrid/utils/getStockStatus';
import {BASE_URL} from '../../constants';
import {formatDate} from '../../utils/analytics';

const mapProductForAnalytics = (product, index) => ({
  brand: product.brandName || null,
  category:
    product.categoryName ||
    (product.mostSpecificCategoryBreadcrumbs &&
      product.mostSpecificCategoryBreadcrumbs.length > 0 &&
      product.mostSpecificCategoryBreadcrumbs
        .map(({name: categoryName}) => categoryName)
        .join('/')) ||
    null,
  image_url: product.imageUrl || (product.imageUrls && product.imageUrls[0]),
  name: product.name || null,
  position: index || undefined,
  price:
    product.fundType === 'unlimited'
      ? product.priceCents
      : Number((product.priceCents / 100).toFixed(2)) || null,
  product_id: product.productId?.toString() || null,
  sku: product.sku || null,
  url: product.detailPageUrlKey
    ? `${BASE_URL}/registry/store/products/${product.detailPageUrlKey.replace(
        '.html',
        '',
      )}`
    : product.productUrl,
  store: product.storeName,
  date_added_to_wishlist: (() => {
    const createdAt = product.createdAt || product.timestamp?.createdAt;
    return createdAt ? formatDate(createdAt) : undefined;
  })(),
  couple_customization: {
    coupleNote: !!product.coupleNote,
    topChoice: !!product.topChoice,
  },
  stock_status: getStockStatus(product),
  average_rating: product.reviews?.avgRating || null,
  affiliation: product.affiliation || null,
});

export default mapProductForAnalytics;
