import React, {createContext, useState} from 'react';
import PropTypes from 'prop-types';

const defaultMarkFulfilledInfo = {
  step: 1,
  guestFullName: undefined,
  quantity: 1,
  orderNumber: undefined,
  whetherHaveOrderNumber: true,
};
export const MarkUnitAsFulfilledContext = createContext({
  markFulfilledInfo: defaultMarkFulfilledInfo,
});
export const MarkUnitAsFulfilledContextProvider = ({children}) => {
  const [markFulfilledInfo, setMarkFulfilledInfo] = useState(
    defaultMarkFulfilledInfo,
  );

  const initialMarkFulfilledInfo = () => {
    setMarkFulfilledInfo(defaultMarkFulfilledInfo);
  };
  return (
    <MarkUnitAsFulfilledContext.Provider
      value={{
        markFulfilledInfo,
        setMarkFulfilledInfo,
        initialMarkFulfilledInfo,
      }}>
      {children}
    </MarkUnitAsFulfilledContext.Provider>
  );
};

MarkUnitAsFulfilledContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
