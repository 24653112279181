import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@xo-union/tk-component-icons';
import {StatusBadge} from '@xo-union/tk-component-badges';
import {compose} from '@xo-union/react-css-modules';
import styles from './styles.scss';

const TopChoiceBadge = ({className}) => {
  return (
    <StatusBadge
      classes={compose({container: className})}
      size="lg"
      color="secondary-alternative"
      type="featured">
      <Icon className={styles.trophy} name="trophy" size="sm" />
      Our Top Choice
    </StatusBadge>
  );
};

TopChoiceBadge.defaultProps = {
  className: '',
};

TopChoiceBadge.propTypes = {
  className: PropTypes.string,
};

export default TopChoiceBadge;
