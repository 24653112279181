import {create} from 'zustand';

const useGlobalProductStore = create((set) => ({
  setHasDoneFirstFetch: () =>
    set((state) => ({...state, hasDoneFirstProductsFetch: true})),
  setStores: (stores) => set((state) => ({...state, stores})),
  hasDoneFirstProductsFetch: false,
  /**
   * useProducts caches "stores" inside this global store instead of using the react-query
   * cache so that the SortFiltersContext can access it without causing a
   * dependency cycle.
   */
  stores: [],
}));

export default useGlobalProductStore;
