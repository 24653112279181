import React, {createContext, useState} from 'react';
import PropTypes from 'prop-types';

export const ErrorContext = createContext({
  error: '',
  modalError: '',
  setError: () => {},
  setModalError: () => {},
});

export const ErrorContextProvider = ({children}) => {
  const [error, setError] = useState('');
  const [modalError, setModalError] = useState('');

  return (
    <ErrorContext.Provider
      value={{
        error,
        modalError,
        setError,
        setModalError,
      }}>
      {children}
    </ErrorContext.Provider>
  );
};

ErrorContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
