/* eslint-disable import/no-extraneous-dependencies */
import React, {createContext, useState} from 'react';
import Overlay from '@xo-union/tk-component-overlay';
import PropTypes from 'prop-types';

export const DialogContext = createContext({
  toggleDialog: () => {},
});

export const DialogContextProvider = ({children}) => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState(null);

  const toggleDialog = ({
    isVisible = !visible,
    content: dialogContent = null,
  }) => {
    if (dialogContent) {
      setContent(dialogContent);
    }
    setVisible(isVisible);
  };

  const onCloseDialog = () => {
    setVisible(false);
    setContent(null);
  };

  return (
    <DialogContext.Provider
      value={{
        toggleDialog,
        onCloseDialog,
      }}>
      {visible && <Overlay>{content}</Overlay>}
      {children}
    </DialogContext.Provider>
  );
};

DialogContextProvider.propTypes = {
  children: PropTypes.node,
};

DialogContextProvider.defaultProps = {
  children: null,
};
