const SORT_OPTION_RETAILERS = {
  property: 'retailerSortOrder',
  direction: 'asc',
};

const getSortFilters = () => {
  const sortFilters = {filters: {}, sort: []};

  const sortItem = SORT_OPTION_RETAILERS;

  sortFilters.sort.push(sortItem);

  return sortFilters;
};

export default getSortFilters;
