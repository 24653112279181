import React from 'react';
import {compose} from '@xo-union/react-css-modules';
import {Column, Row, TopLevelContainer} from '@xo-union/tk-component-grid';
import Spinner from '@xo-union/tk-component-spinner';
import style from './style.scss';

const spinnerClasses = compose({
  'top-level-container': style['top-level-container'],
});

const GridSpinner = () => (
  <TopLevelContainer classes={spinnerClasses} data-testid="loading-container">
    <Row>
      <Column xs="12">
        <Spinner />
      </Column>
    </Row>
  </TopLevelContainer>
);

export default GridSpinner;
