import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {RadioButton, SingleSelectGroup} from '@xo-union/tk-component-switches';
import {ProductsContext} from '../../contexts/ProductsContext';
import {SortFiltersContext} from '../../contexts/SortFiltersContext';
import getArrayOfLabels from '../../utils/getArrayOfLabels';
import makeTestId from '../../utils/makeTestId';

const SingleSelectFilter = ({testId, type}) => {
  const {updateProduct} = useContext(ProductsContext);
  const {filterOptions, selectFilter, selectedFilterOptions} =
    useContext(SortFiltersContext);

  return (
    <SingleSelectGroup
      onChange={(e) => selectFilter(type, e.target.value, updateProduct)}
      value={selectedFilterOptions[type].label}>
      {getArrayOfLabels(filterOptions[type]).map((label) => (
        <RadioButton
          data-testid={makeTestId(
            testId,
            `singleselect-${type}-option-${label}`,
          )}
          key={label}
          value={label}>
          {label}
        </RadioButton>
      ))}
    </SingleSelectGroup>
  );
};

SingleSelectFilter.defaultProps = {
  testId: '',
};

SingleSelectFilter.propTypes = {
  testId: PropTypes.string,
  type: PropTypes.oneOf(['sort']).isRequired,
};

export default SingleSelectFilter;
