import _uniq from 'lodash.uniq';
import trackProductWishlistViewed from '../analytics/trackProductWishlistViewed';

export const TRACKING_TIME_IN_SECONDS = 3;

/**
 * Creates a queue for efficiently tracking impressions.
 */
class ImpressionTracking {
  constructor() {
    this.impressionsToSend = [];
    this.autoTrack = null;

    // we must bind the `this` context since the queueImpressions method (or any others)
    // become a higher order function.
    this.sendImpressions = this.sendImpressions.bind(this);
    this.startAutoTrack = this.startAutoTrack.bind(this);
    this.blurHandler = this.blurHandler.bind(this);
    this.focusHandler = this.focusHandler.bind(this);
    this.queueImpressions = this.queueImpressions.bind(this);
    this.cancelImpressionTracking = this.cancelImpressionTracking.bind(this);
    this.impressionTracking = this.impressionTracking.bind(this);
    this.clearImpressionsQueue = this.clearImpressionsQueue.bind(this);

    // instantiate impression tracking
    this.impressionTracking();
  }

  sendImpressions() {
    if (this.impressionsToSend.length === 0) return;

    const mappedImpressions = this.impressionsToSend
      .sort(
        (
          {eventData: {position: positionA}},
          {eventData: {position: positionB}},
        ) => positionA - positionB,
      )
      .map(({eventData: {product, position}}) => ({...product, position}));

    const {memberId, member, weddingDate} =
      this.impressionsToSend[0]?.eventData;

    trackProductWishlistViewed({
      memberId,
      member,
      weddingDate,
      products: mappedImpressions,
    });

    this.clearImpressionsQueue();
  }

  startAutoTrack() {
    clearInterval(this.autoTrack);

    this.autoTrack = setInterval(() => {
      this.sendImpressions();
    }, TRACKING_TIME_IN_SECONDS * 1000);
  }

  blurHandler() {
    clearInterval(this.autoTrack);
    this.sendImpressions();
  }

  focusHandler() {
    clearInterval(this.autoTrack);
    this.startAutoTrack();
  }

  queueImpressions(newProductImpressions) {
    this.impressionsToSend = _uniq(
      this.impressionsToSend.concat(newProductImpressions),
    );
  }

  cancelImpressionTracking() {
    window.removeEventListener('blur', this.blurHandler);
    window.removeEventListener('focus', this.focusHandler);
  }

  impressionTracking() {
    this.startAutoTrack();
    window.addEventListener('blur', this.blurHandler);
    window.addEventListener('focus', this.focusHandler);
  }

  clearImpressionsQueue() {
    this.impressionsToSend = [];
  }
}

// export as singleton
const impressionTracking = new ImpressionTracking();

export default impressionTracking;
