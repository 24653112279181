export const stopPropagation = (e) => {
  const event = e || window.event;

  event.cancelBubble = true;

  if (event.stopPropagation) event.stopPropagation();
};

export const onEnter = (callback) => (event) => {
  if (event.key === 'Enter') {
    callback(event);
  }
};
