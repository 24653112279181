import React, {useContext} from 'react';
import {startCase} from 'lodash-es';
import PropTypes from 'prop-types';
import {Body1, Body2} from '@xo-union/tk-ui-typography';
import {Button} from '@xo-union/tk-component-buttons';
import {ProductsContext} from '../../../../contexts/ProductsContext';
import {AppContext} from '../../../../contexts/AppContext';
import {useCoupleSummary} from '../../../../../../queries/couple';
import {
  getImage,
  getStoreName,
  getPrice,
} from '../../../../../../utils/registryItem';
import getAmountNeeded from '../../../../utils/getAmountNeeded';
import makeTestId from '../../../../utils/makeTestId';
import style from './style.scss';

const PurchaseRemind = ({onClickNotBuyButton, onClickDidBuyButton}) => {
  const {currentProduct} = useContext(ProductsContext);
  const {memberId} = useContext(AppContext);
  const {data: {fianceFirstName, firstName} = {}} = useCoupleSummary(memberId);
  const storeName = startCase(getStoreName(currentProduct).toLowerCase());
  const image = getImage(currentProduct);
  const {amountNeeded} = getAmountNeeded(currentProduct);
  return (
    <div>
      <Body1 className={style['universal-product-content-title']}>
        Purchased this gift?
      </Body1>
      <Body2 className={style['universal-product-content-subtitle']}>
        Letting us know helps {firstName} and {fianceFirstName} track their
        gifts.
      </Body2>
      <Body1 className={style['gift-detail']}>
        <Body1 className={style.product}>
          <img className={style.image} src={image} alt="" />
          <Body1 className={style.info}>
            <Body2 className={style.brand}>{storeName}</Body2>
            <Body1 className={style.name}>{currentProduct.name}</Body1>
            <Body1 className={style.price}>${getPrice(currentProduct)}</Body1>
            <Body2 className={style.need}>{amountNeeded}</Body2>
          </Body1>
        </Body1>
      </Body1>
      <div className={style['gift-prompt-actions']}>
        <Button
          className={style['gift-prompt-action-button']}
          color="secondary"
          data-testid={makeTestId(
            'product-grid',
            'modal-content-did-not-buy-btn',
          )}
          onClick={onClickNotBuyButton}
          size="lg">
          No
        </Button>
        <Button
          className={style['gift-prompt-action-button']}
          color="primary"
          data-testid={makeTestId('product-grid', 'modal-content-did-buy-btn')}
          onClick={onClickDidBuyButton}
          size="lg">
          Yes
        </Button>
      </div>
    </div>
  );
};

PurchaseRemind.propTypes = {
  onClickNotBuyButton: PropTypes.func.isRequired,
  onClickDidBuyButton: PropTypes.func.isRequired,
};

export default PurchaseRemind;
