import React from 'react';
import PropTypes from 'prop-types';
import {Body2} from '@xo-union/tk-ui-typography';
import Icon from '@xo-union/tk-component-icons';
import {onEnter} from '../../../../utils/event';
import style from './style.scss';

const FilterPill = ({label, onClick}) => (
  <div
    aria-label={`Filtered by ${label}`}
    className={style['filter-pill-container']}
    data-testid={`filter-pill-${label}-container`}
    onClick={() => onClick()}
    onKeyDown={() => onEnter(onClick)}
    role="button"
    tabIndex="0">
    <Body2
      className={style['label-container']}
      data-testid={`filter-pill-${label}`}>
      {label}
      <Icon className={style['icon-close']} name="close" size="sm" />
    </Body2>
  </div>
);

FilterPill.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FilterPill;
