import dateformat from 'dateformat';
import React from 'react';
import PropTypes from 'prop-types';
import {Body1} from '@xo-union/tk-ui-typography';
import {useCoupleSummary} from '../../../../queries/couple';
import CoupleNames from '../CoupleNames';
import WeddingWebsite from '../WeddingWebsite';
import style from './style.scss';

const CoupleInfo = ({memberId, coupleUuid}) => {
  const {data: coupleSummary, hasCoupleSummary} = useCoupleSummary(
    memberId || coupleUuid,
  );
  const {
    city,
    state,
    eventDate,
    firstName,
    lastName,
    fianceFirstName,
    fianceLastName,
    wws,
  } = coupleSummary;

  const isHiddenDate = wws?.isHiddenDate || false;
  const location = [city, state].join(', ');
  const formatEventDate = eventDate
    ? dateformat(eventDate, 'UTC:mmmm dd, yyyy')
    : null;

  if (!hasCoupleSummary) return null;

  return (
    <div
      className={style['couple-info']}
      data-testid="gvr-couple-info-container">
      <CoupleNames
        firstName={firstName}
        lastName={lastName}
        fianceFirstName={fianceFirstName}
        fianceLastName={fianceLastName}
      />
      <Body1
        className={style['event-info']}
        data-testid="gvr-couple-event-info-container">
        {location}
        {isHiddenDate ? (
          <> &bull; To Be Announced</>
        ) : (
          formatEventDate && <> &bull; {formatEventDate}</>
        )}
      </Body1>
      {wws?.isHiddenWebsite === false && (
        <WeddingWebsite websiteUrl={wws?.websiteUrl} />
      )}
    </div>
  );
};

CoupleInfo.propTypes = {
  memberId: PropTypes.string,
  coupleUuid: PropTypes.string,
};

CoupleInfo.defaultProps = {
  memberId: null,
  coupleUuid: null,
};

export default CoupleInfo;
