import analyticsWrapper from '@xo-union/tk-analytics';
import {getProductBaseAnalytics} from './getBaseAnalytics';
import trackCashFund from './trackCashFund';
import {
  CASH_FUND,
  RETAILER_PRODUCT,
  UNIVERSAL_REGISTRY_PRODUCT,
  TRANSACTIONAL_PRODUCT,
} from '../../../../constants';

const trackProductCardClick = ({
  application,
  isMobile,
  memberId,
  product,
  registry,
  source = 'product card',
}) => {
  const {name, storeName, type, brandName} = product;
  const analytics = getProductBaseAnalytics(
    isMobile,
    application,
    product,
    memberId,
    source,
  );

  switch (type) {
    case CASH_FUND:
      trackCashFund(
        isMobile,
        application,
        product,
        'open details',
        memberId,
        source,
      );
      break;
    case RETAILER_PRODUCT:
      analyticsWrapper.track('Click-through to Retailer', {
        ...analytics,
        registryType: Number(registry.registryType) === 1 ? 'linked' : 'manual',
        retailer: registry.retailer.retailerName,
        source,
      });
      break;
    case TRANSACTIONAL_PRODUCT:
      analyticsWrapper.track('Click-through to Transactional', {
        ...analytics,
        brandName,
        hasCoupleNote: !!product.coupleNote,
        source,
      });
      break;
    case UNIVERSAL_REGISTRY_PRODUCT:
      analyticsWrapper.track('Universal Registry Interaction', {
        ...analytics,
        name,
        selection: 'view details',
        source,
        store: storeName,
        userDecisionArea: 'product card',
      });

      break;
    default:
      break;
  }
};

export default trackProductCardClick;
