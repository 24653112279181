import React from 'react';
import PropTypes from 'prop-types';
import {useFlagsStatus} from '@unleash/proxy-client-react';
import {H2} from '@xo-union/tk-ui-typography';
import {TopLevelContainer, Row, Column} from '@xo-union/tk-component-grid';
import CartIcon from '@tkww/reg-shared-ui-cart-icon';
import ProductGrid from '../../components/ProductGrid';
import RegistryList from '../../components/RegistryList';
import OverlaySpinner from '../../components/OverlaySpinner';
import {APPLICATION_WWS_OLD_TEMPLATE} from '../../constants';
import {useCoupleSummary} from '../../queries/couple';
import {shouldShowRegistries} from '../../utils/registry';
import {queryClient} from '../../queries';
import style from './style.scss';

const WWS = ({application, memberId}) => {
  const {flagsReady} = useFlagsStatus();
  const {data: coupleSummary, hasCoupleSummary} = useCoupleSummary(memberId);

  if (!hasCoupleSummary || !flagsReady) return <OverlaySpinner />;

  const {hasTransactionalRegistry, isHiddenProducts, registries} =
    coupleSummary;

  return (
    <>
      <TopLevelContainer
        /**
         * turbolinks=false to disable a bug regarding
         * weird behavior with Rails, Links, and CSS.
         */
        data-turbolinks="false">
        <Row>
          <Column xs="12" className={style.header}>
            <CartIcon
              application={application}
              withCircle
              withRedirect
              showAlways={false}
              onClick={null}
              size="sm"
              queryClient={queryClient}
            />
          </Column>
        </Row>

        {shouldShowRegistries(registries, [], hasTransactionalRegistry) && (
          <Row>
            <Column xs="12">
              <div className={style['row-registry-list']}>
                <H2 className={style['row-header']}>Gift Providers</H2>
                <RegistryList application={application} memberId={memberId} />
              </div>
            </Column>
          </Row>
        )}
      </TopLevelContainer>

      {!isHiddenProducts && (
        <ProductGrid
          application={application}
          hasTransactionalRegistry={hasTransactionalRegistry}
          memberId={memberId}
        />
      )}
    </>
  );
};

WWS.propTypes = {
  application: PropTypes.string,
  memberId: PropTypes.string,
};

WWS.defaultProps = {
  application: APPLICATION_WWS_OLD_TEMPLATE,
  memberId: '',
};

export default WWS;
