const transformFilters = (initialFilters) => {
  const filters = [];
  const filterKeys = Object.keys(initialFilters);

  if (filterKeys.indexOf('fulfilled') !== -1) {
    filters.push({
      type: 'fulfilled',
      value: initialFilters.fulfilled,
    });
  }

  if (filterKeys.indexOf('priceRange') !== -1) {
    initialFilters.priceRange.forEach(({min, max}) => {
      filters.push({
        type: 'priceRange',
        value: `${Number(min) / 100}-${max === '*' ? max : Number(max) / 100}`,
      });
    });
  }

  if (filterKeys.indexOf('stores') !== -1) {
    filters.push({
      type: 'stores',
      value: initialFilters.stores.join(', '),
    });
  }

  return filters;
};

const transformSort = (sort) =>
  sort.map(({direction, property}) => ({
    type: property,
    value: direction,
  }));

const mapFilters = ({filters = {}, sort = []}) => ({
  filters: transformFilters(filters),
  sorts: transformSort(sort),
});

export default mapFilters;
