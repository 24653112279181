import '@xo-union/tk-ui-essentials';
import {Honeybadger, HoneybadgerErrorBoundary} from '@honeybadger-io/react';
import {QueryClientProvider} from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom';
import {FlagProvider} from '@unleash/proxy-client-react';
import GVR from './views/GVR';
import WWS from './views/WWS';
import {APPLICATION_WWS, UNLEASH_CONFIG} from './constants';
import {safeParse, toTitleCase} from './utils/string';
import {transform} from './utils/object';
import {queryClient} from './queries';
import filterHoneybadgerNotices from './utils/honeybadger';
import packageInfo from '../package.json';

const appRoot = document.getElementById('products-grid-app-root');

const honeybadgerConfig = {
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.ENV,
  revision: process.env.GIT_REVISION,
  enableUncaught: false,
};

if (appRoot) {
  const honeybadger = Honeybadger.configure(honeybadgerConfig);

  const {
    application = APPLICATION_WWS,
    coupleData: coupleDataRaw = {},
    coupleId = '',
    initialItems = [],
    isMobile = false,
    memberId = '',
  } = appRoot.dataset;

  window.APP_VERSION = packageInfo.version;

  Honeybadger.setContext({
    application,
    coupleId,
    memberId,
  });

  // Ignore errors that come from pages other than the standalone GVR or registry tab of the wedding website
  Honeybadger.beforeNotify(filterHoneybadgerNotices);

  const coupleData = transform(safeParse(coupleDataRaw), {
    '*': (value) => (typeof value === 'string' ? value.trim() : value),
    '*uuid': (value) => value.toLowerCase(),
    '*name': (value) => toTitleCase(value),
    city: (value) => toTitleCase(value),
  });

  if (!memberId && coupleData.coupleUuid) {
    queryClient.setQueryData(
      ['couple-summary', coupleData.coupleUuid],
      coupleData,
    );
  }

  const App = () =>
    application === 'gvr' ? (
      <GVR
        coupleData={coupleData}
        initialItems={safeParse(initialItems)}
        isMobile={isMobile === 'true'}
      />
    ) : (
      <WWS application={application} coupleId={coupleId} memberId={memberId} />
    );

  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <FlagProvider config={UNLEASH_CONFIG}>
        <HoneybadgerErrorBoundary honeybadger={honeybadger}>
          <App />
        </HoneybadgerErrorBoundary>
      </FlagProvider>
    </QueryClientProvider>,
    appRoot,
  );
}
