import React from 'react';
import PropTypes from 'prop-types';
import getCoupleNames from './getCoupleNames';
import style from './style.scss';

const CoupleNames = ({
  firstName,
  lastName,
  fianceFirstName,
  fianceLastName,
}) => (
  <div>
    <h1 className={style.name}>
      {getCoupleNames(firstName, lastName, fianceFirstName, fianceLastName)}
    </h1>
  </div>
);

CoupleNames.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  fianceFirstName: PropTypes.string.isRequired,
  fianceLastName: PropTypes.string.isRequired,
};

export default CoupleNames;
