import isPartnerRetailerRegistry from '../../../utils/isPartnerRetailerRegistry';

import {
  FEED_REGISTRY_TYPE,
  UNIVERSAL_REGISTRY_RETAILER_ID,
} from '../../../constants';

const normalizeUUID = (uuid) => uuid && uuid.toUpperCase();
const isRetailerActivePartner = (registry) =>
  registry &&
  registry.registryType === FEED_REGISTRY_TYPE &&
  registry.retailer.id !== UNIVERSAL_REGISTRY_RETAILER_ID
    ? isPartnerRetailerRegistry(registry.retailer)
    : true;

const filterProducts = (products, registries) => {
  const registriesByUUID = registries.reduce(
    (acc, registry) => ({
      ...acc,
      [normalizeUUID(registry.registryUuid)]: registry,
    }),
    {},
  );

  return products.filter(
    (product) =>
      !product.isArchived &&
      !product.discontinued &&
      isRetailerActivePartner(
        registriesByUUID[normalizeUUID(product.registryId)],
      ),
  );
};

export default filterProducts;
