import {
  GVR_PRODUCT_GRID_CLICK_SOURCE_ID,
  SOURCE_AFFILIATE_ID_WWS,
  TRACK_HOST,
  WWS_PRODUCT_GRID_CLICK_SOURCE_ID,
} from '../../../constants';

const getTrackUrl = (sourceAffiliateId, product, registry = {}) => {
  const clickSourceId =
    sourceAffiliateId === SOURCE_AFFILIATE_ID_WWS
      ? WWS_PRODUCT_GRID_CLICK_SOURCE_ID
      : GVR_PRODUCT_GRID_CLICK_SOURCE_ID;
  const manualUrl = registry && registry.manualRegistryUrl;
  const trackMethod = manualUrl ? 'manual' : 'view';

  return `${TRACK_HOST}/track/${trackMethod}/${clickSourceId}?rt=${product.retailerId}&r=${registry.id}`;
};

export default getTrackUrl;
