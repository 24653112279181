import {useContext} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import {createCartCookie, useGetCartId} from '@tkww/reg-shared-ui-cart-icon';
import {AppContext} from '../components/ProductGrid/contexts/AppContext';
import {getSessionToken, getCartCookie} from '../utils/session';
import {INTERNAL_GIFT_CARD, MAGENTO_API_HOST} from '../constants';
import {useDenominationGiftingItems} from '../queries/product';

const getHeaders = (token) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'tkww-application': 'fe-guest-registry',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

const CART_ITEM_PRICES_QUERY = `
query getCartItemPrices($cartId: String!) {
  cart(cart_id: $cartId) {
      items {
        id
        quantity
        product {
          sku
          price: selectedOfferPrice
        }
        ... on GiftCardCartItem {
          amount {
            value
          }
        }
      }
    }
  }
`;

const UPDATE_ITEM_QUANTITY_MUTATION = `
mutation updateCartItemQuantity(
  $cartId: String!
  $itemId: Int!
  $newQuantity: Float!
) {
  updateCartItems(
    input: {
      cart_id: $cartId
      cart_items: [{ cart_item_id: $itemId, quantity: $newQuantity }]
    }
  ) {
    cart {
      items {
        id
        quantity
      }
    }
  }
}
`;

const CART_ITEMS_QUERY = `
query getCartItems($cartId: String!) {
  cart(cart_id: $cartId) {
      items {
        id
        quantity
        product {
          brand: brand_name
          name
          sku
          price: selectedOfferPrice
          offerQuantity: selectedOfferQty
          image: small_image {
            url
          }
          url: canonical_url
          categories {
            id
            name
            path
          }
        }
        ... on GiftCardCartItem {
					amount {
						value
					}
				}
      }
    }
  }
  `;

export const Magento = {
  getAuthToken: async (sessionToken) => {
    if (!sessionToken) {
      return null;
    }

    const res = await fetch(
      `${MAGENTO_API_HOST}/rest/V1/giftregistry/customer/token?xoToken=${sessionToken}`,
      {
        method: 'POST',
        headers: getHeaders(),
      },
    );

    if (!res.ok) {
      const error = new Error(res.statusText);
      error.status = res.status;
      throw error;
    }

    const token = await res.json();
    return token;
  },
  createGuestCart: async () => {
    const response = await fetch(`${MAGENTO_API_HOST}/rest/V1/guest-carts`, {
      headers: getHeaders(),
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Failed to create a guest cart.');
    }

    return response.json();
  },
  createMemberCart: async (sessionToken) => {
    const magentoToken = await Magento.getAuthToken(sessionToken);

    const response = await fetch(`${MAGENTO_API_HOST}/graphql`, {
      method: 'POST',
      body: JSON.stringify({
        query: `query { customerCart { id } }`,
      }),
      headers: getHeaders(magentoToken),
    });

    if (!response.ok) {
      throw new Error('Failed to create a member cart.');
    }

    const {data} = await response.json();

    return data?.customerCart?.id || null;
  },
  getCartItems: async (cartId) => {
    const sessionToken = getSessionToken();
    const magentoToken = await Magento.getAuthToken(sessionToken);
    return fetch(`${MAGENTO_API_HOST}/graphql`, {
      method: 'POST',
      body: JSON.stringify({
        query: CART_ITEMS_QUERY,
        variables: {cartId},
      }),
      headers: getHeaders(magentoToken),
    })
      .then((res) => res.json())
      .then((res) => res.data?.cart?.items);
  },
  getItemPrices: async (cartId) => {
    const sessionToken = getSessionToken();
    const magentoToken = await Magento.getAuthToken(sessionToken);
    return fetch(`${MAGENTO_API_HOST}/graphql`, {
      method: 'POST',
      headers: getHeaders(magentoToken),
      body: JSON.stringify({
        query: CART_ITEM_PRICES_QUERY,
        variables: {cartId},
      }),
    })
      .then((res) => res.json())
      .then((res) => res.data?.cart?.items || [])
      .then((items) =>
        items.reduce((acc, item) => {
          const {
            id,
            quantity,
            product: {price, sku},
            amount,
          } = item;
          const giftCardAmount = amount?.value;
          const total = giftCardAmount || quantity * price;
          return {...acc, [id]: {total, sku}};
        }, {}),
      );
  },
  removeItemFromCart: async ({cartId, itemId}) => {
    const sessionToken = getSessionToken();
    const magentoToken = await Magento.getAuthToken(sessionToken);
    return fetch(`${MAGENTO_API_HOST}/graphql`, {
      method: 'POST',
      headers: getHeaders(magentoToken),
      body: JSON.stringify({
        query: UPDATE_ITEM_QUANTITY_MUTATION,
        variables: {cartId, itemId, newQuantity: 0},
      }),
    })
      .then((res) => res.json())
      .then((res) => res.data?.cart?.items || []);
  },
};

export const createCartAndSetCookie = async () => {
  const sessionToken = getSessionToken();
  const cartCookie = getCartCookie();

  if (cartCookie) return cartCookie;

  let cartId;

  if (sessionToken) {
    cartId = await Magento.createMemberCart(sessionToken);
  } else {
    cartId = await Magento.createGuestCart();
  }

  createCartCookie(cartId);

  return cartId;
};

export const useCartItems = ({cartId}) => {
  return useQuery(
    ['cart-items', cartId],
    async () => {
      if (!cartId) {
        return [];
      }

      return Magento.getCartItems(cartId);
    },
    {staleTime: Infinity},
  );
};

export const useAddItemToCart = () => {
  const {data: cartIdFromCookie} = useGetCartId();
  const {data: denominationalItems = []} = useDenominationGiftingItems();
  const {memberId} = useContext(AppContext);

  return useMutation(
    async ({sku, quantity, offerId, giftCardAmount, price, product}) => {
      const cartId = cartIdFromCookie || (await createCartAndSetCookie());

      /**
       * For "Denomination Gifting" items, prevent the guest
       * from adding more than the remaining gift card amount
       * to their cart.
       */
      const denominationGiftingItem = denominationalItems.find((dgI) =>
        dgI.variantSkus.includes(sku),
      );

      if (denominationGiftingItem) {
        const cartItemPurchaseAmts = await Magento.getItemPrices(cartId);

        const {amountFulfilled, amountRequested} = denominationGiftingItem;
        const purchaseableAmount = amountRequested - amountFulfilled;

        const amountInCart = Object.entries(cartItemPurchaseAmts).reduce(
          (acc, curr) => {
            if (curr[1].sku === sku) {
              return acc + curr[1].total;
            }

            return acc;
          },
          0,
        );

        const amountAddingToCart = quantity * price;

        if (amountInCart + amountAddingToCart > purchaseableAmount) {
          throw new Error(
            `Heads up! The maximum amount of this gift card that you can purchase is $${purchaseableAmount}.`,
          );
        }
      }

      /**
       * For internal/tkrs gift cards, combine
       * any gift cards that are currently in the cart
       * with the newly added amount.
       */
      let giftCardPurchaseAmount = giftCardAmount;
      if (product.type === INTERNAL_GIFT_CARD) {
        const maxContribution = product.allowedContributionRange.max;
        const cartItemPurchaseAmts = await Magento.getItemPrices(cartId);

        const tkrsGiftCardAmountInCart = Object.entries(
          cartItemPurchaseAmts,
        ).reduce((acc, curr) => {
          if (curr[1].sku === sku) {
            return acc + curr[1].total;
          }

          return acc;
        }, 0);

        if (
          tkrsGiftCardAmountInCart + giftCardPurchaseAmount >
          maxContribution
        ) {
          throw new Error(
            `Heads up! The maximum amount of this gift card that you can purchase is $${maxContribution}.`,
          );
        }

        if (tkrsGiftCardAmountInCart) {
          const itemsToRemove = Object.entries(cartItemPurchaseAmts).reduce(
            (acc, curr) => {
              if (curr[1].sku === sku) {
                acc.push(curr[0]);
              }

              return acc;
            },
            [],
          );

          await Promise.all(
            itemsToRemove.map((id) =>
              Magento.removeItemFromCart({cartId, itemId: id}),
            ),
          );

          // add the removed gift card price to current GC amount
          giftCardPurchaseAmount += tkrsGiftCardAmountInCart;
        }
      }

      const payload = {
        qty: quantity,
        quote_id: cartId,
        sku,
        extension_attributes: {
          member_id: memberId,
        },
      };

      if (giftCardPurchaseAmount) {
        payload.product_option = {
          extension_attributes: {
            giftcard_item_option: {
              giftcard_amount: 'custom',
              custom_giftcard_amount: giftCardPurchaseAmount,

              /**
               * The following fields are required by Magento
               * but are not used by our gift card implementation.
               *
               * For now they are needed to allow for successful
               * gift card cart item creation.
               */
              giftcard_recipient_name: 'TKWW Registry',
              giftcard_sender_name: 'TKWW Registry Guest',
              giftcard_recipient_email: 'test@test.com',
              giftcard_sender_email: 'testing@tester.com',
            },
          },
        };
      } else {
        payload.extension_attributes.offer_id = offerId;
      }

      const response = await fetch(
        `${MAGENTO_API_HOST}/rest/V1/guest-carts/${cartId}/items`,
        {
          headers: getHeaders(),
          method: 'POST',
          body: JSON.stringify({cartItem: payload}),
        },
      );

      const body = await response.json();

      if (!response.ok) {
        let errMessage = body.message;

        if (errMessage && Object.keys(body?.parameters || {}).length) {
          errMessage = body.message.replace(
            '%fieldName',
            body.parameters.fieldName,
          );
        }

        throw new Error(
          errMessage ||
            `Unable to add sku ${sku} to cart: ${response.statusText}`,
        );
      }

      return cartId;
    },
  );
};
