import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useCoupleSummary} from '../../queries/couple';
import useGlobalProductStore from '../../hooks/useGlobalProductStore';
import {useProducts} from '../../queries/product';
import {formatDate} from '../../utils/analytics';

const PageLoaded = ({memberId, children}) => {
  const {data: coupleSummary = {}} = useCoupleSummary(memberId);
  const {hasDoneFirstProductsFetch} = useGlobalProductStore();
  const {data: {products = []} = {}} = useProducts(memberId);

  useEffect(() => {
    if (!memberId) return;

    if (hasDoneFirstProductsFetch) {
      window.analytics.page({
        wishlist_name: 'Transactional Registry',
        wishlist_id: memberId.toLowerCase(),
        list_id: 'guest-registry',
        affiliation: products.map((product) => product.affiliation),
        weddingDate: formatDate(coupleSummary.eventDate),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId, hasDoneFirstProductsFetch]);

  return <>{children}</>;
};

PageLoaded.defaultProps = {
  children: null,
};

PageLoaded.propTypes = {
  memberId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default PageLoaded;
