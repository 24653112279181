/* eslint-disable import/prefer-default-export */
import {useQuery} from '@tanstack/react-query';
import {CORE_API_HOST} from '../constants';

export const Couple = {
  getSummary: async (memberId) => {
    const response = await fetch(
      `https://${CORE_API_HOST}/v2/members/${memberId}/public-summary?eventType=wedding`,
    );

    if (!response.ok) {
      throw new Error(`Unable to get couple summary: ${response.statusText}`);
    }

    return response.json();
  },
};

export const useCoupleSummary = (memberId) => {
  const {...coupleQuery} = useQuery(
    ['couple-summary', memberId],
    () => {
      if (!memberId) return {};
      return Couple.getSummary(memberId);
    },
    {staleTime: Infinity},
  );
  return {
    ...coupleQuery,
    hasCoupleSummary: Object.keys(coupleQuery.data ?? {}).length > 0,
  };
};
