import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {useMobileMedia} from '@xo-union/tk-ui-breakpoints';
import RegistryListItem from './components/RegistryListItem';
import {
  APPLICATION_GVR,
  APPLICATION_WWS,
  APPLICATION_WWS_OLD_TEMPLATE,
  SOURCE_AFFILIATE_ID_REGISTRY,
  SOURCE_AFFILIATE_ID_WWS,
} from '../../constants';
import {useCoupleSummary} from '../../queries/couple';
import {
  getShownRegistries,
  sortRegistries,
  getShowCashRegistry,
} from '../../utils/registry';
import style from './style.scss';

const RegistryList = ({
  application,
  className,
  isMobile: isMobileInitial,
  itemClassName,
  memberId,
}) => {
  const {data: coupleSummary = {}} = useCoupleSummary(memberId);
  const isMobileDevice = !!useMobileMedia().yes;
  const {registries, hasTransactionalRegistry} = coupleSummary;

  const sourceAffiliateId =
    application === APPLICATION_GVR
      ? SOURCE_AFFILIATE_ID_REGISTRY
      : SOURCE_AFFILIATE_ID_WWS;
  const showCashRegistry = getShowCashRegistry(coupleSummary);
  const filteredRegistries = getShownRegistries(registries, showCashRegistry);
  const registryList = sortRegistries(filteredRegistries);

  const isSingleItem = registryList.length === 1;

  let containerClasses = cx(className, {
    [style['registry-list-gvr']]:
      !isSingleItem && application === APPLICATION_GVR,
    [style['registry-list-wws']]:
      !isSingleItem && application !== APPLICATION_GVR,
    [style['registry-list-single']]: isSingleItem && !hasTransactionalRegistry,
  });

  if (hasTransactionalRegistry) {
    containerClasses = className;
  }

  const itemClasses = cx(itemClassName, {
    [style['registry-list-item-single']]:
      !hasTransactionalRegistry && isSingleItem,
  });

  return (
    <div
      className={containerClasses}
      data-testid="registry-list-container"
      name="registry-list">
      {registryList.map((registry) => (
        <RegistryListItem
          application={application}
          className={itemClasses}
          hasTransactionalRegistry={hasTransactionalRegistry}
          isMobile={isMobileDevice || isMobileInitial}
          key={`registry-${registry.id}`}
          memberId={memberId}
          registry={registry}
          sourceAffiliateId={sourceAffiliateId}
        />
      ))}
    </div>
  );
};

RegistryList.propTypes = {
  application: PropTypes.oneOf([
    APPLICATION_GVR,
    APPLICATION_WWS,
    APPLICATION_WWS_OLD_TEMPLATE,
  ]).isRequired,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  itemClassName: PropTypes.string,
  memberId: PropTypes.string,
};

RegistryList.defaultProps = {
  className: '',
  isMobile: undefined,
  itemClassName: '',
  memberId: '',
};

export default RegistryList;
