import mapProductForAnalytics from './helpers/mapProductForAnalytics';
import {formatDate} from '../utils/analytics';

const trackWishListProductClicked = ({
  memberId,
  member,
  product,
  eventDate,
  position,
}) => {
  window.analytics.track({
    event: 'Wishlist Product Clicked',
    properties: {
      wishlist_id: memberId,
      wishlist_name: 'Transactional Registry',
      list_id: 'guest-registry',
      currency: 'USD',
      memberId: member?.id || null,
      event_date: eventDate ? formatDate(eventDate) : undefined,
      ...mapProductForAnalytics(product, position),
    },
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

export default trackWishListProductClicked;
