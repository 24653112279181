import {COUPLE_NAME_LIMIT} from '../../../../constants';

const transformName = (first = '', last = '') =>
  [first, last]
    .filter((name) => !!name && name !== ' ')
    .map((s) => {
      if (s.length > COUPLE_NAME_LIMIT && /([^-\s]{16})/.test(s)) {
        const reg = new RegExp(`(\\S{${COUPLE_NAME_LIMIT}})`, 'g');

        return s.replace(reg, '$1-').replace(/-$/, '');
      }

      return s;
    })
    .join(' ');

const getCoupleNames = (
  firstName = '',
  lastName = '',
  fianceFirstName = '',
  fianceLastName = '',
) => {
  const registrant = transformName(firstName, lastName);
  const coregistrant = transformName(fianceFirstName, fianceLastName);

  return registrant !== '' && coregistrant !== ''
    ? `${registrant} & ${coregistrant}`
    : `${registrant || coregistrant || ''}`;
};

export default getCoupleNames;
