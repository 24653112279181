import {useEffect, useContext, useState, useCallback} from 'react';
import {AppContext} from '../components/ProductGrid/contexts/AppContext';
import {useMember} from '../queries/member';
import {useCoupleSummary} from '../queries/couple';
import impressionTracking from '../utils/impressionTracking';
import setIntersectionObserver from '../utils/setIntersectionObserver';
import useAnalytics from './useAnalytics';

const useImpressionObserver = ({product, position, cardRef}) => {
  const {isReady: isAnalyticsReady} = useAnalytics();
  const {application, memberId} = useContext(AppContext);
  const {data: member} = useMember();
  const {data: coupleSummary = {}} = useCoupleSummary(memberId);
  const [observer, setObserver] = useState(null);

  const getEventData = useCallback(
    () => ({
      product,
      position,
      application,
      memberId,
      member,
      weddingDate: coupleSummary.eventDate,
    }),
    [product, position, application, memberId, member, coupleSummary],
  );

  useEffect(() => {
    return () => {
      if (observer?.disconnect) {
        observer.disconnect();
      }
    };
  }, [observer]);

  useEffect(() => {
    if (!!product && isAnalyticsReady && !observer) {
      const intersectionObserver = setIntersectionObserver({
        callback: impressionTracking.queueImpressions,
        element: cardRef,
        eventData: getEventData(),
      });

      setObserver(intersectionObserver);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, isAnalyticsReady, observer]);
};

export default useImpressionObserver;
