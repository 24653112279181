import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Button, Link} from '@xo-union/tk-component-buttons';
import {Body1, Body2} from '@xo-union/tk-ui-typography';
import {ProductImage} from '@tkww/registry-component-product-details';
import Product from '../../../../../../proptypes/Product';
import {AMOUNT_NEEDED_UNAVAILABLE} from '../../../../../../constants';
import {ModalContext} from '../../../../contexts/ModalContext';
import {formatPrice} from '../../../../../../utils/string';
import {getName, getImage} from '../../../../../../utils/registryItem';
import getAmountNeeded from '../../../../utils/getAmountNeeded';
import style from './style.scss';

const AddToCartSuccess = ({
  cartUrl,
  testId,
  product,
  selectedVariant,
  giftCardAmount,
}) => {
  const {onClose, toggleModal} = useContext(ModalContext);
  const {brandName = '', isUsingDenominationGifting} = product;
  const productName = getName(product);
  const {amountNeeded, priceNeeded: price} = getAmountNeeded(product);

  const handleContinueBrowsing = () => {
    onClose();
    toggleModal({isVisible: false});
  };
  const selectedAmount = Number(selectedVariant?.price).toFixed(2);

  return (
    <>
      <div
        data-testid="transactional-mini-details-image"
        className={style['transactional-mini-details']}>
        <div className={style['transactional-mini-details-image']}>
          <ProductImage image={getImage(product)} />
        </div>

        <div data-testid="transactional-mini-details-product-name">
          <Body2
            className={style['transactional-mini-details-store-name']}
            data-testid={testId('product-store-name')}>
            {brandName}
          </Body2>

          <Body1 data-testid={testId('product-name')}>{productName}</Body1>
          {isUsingDenominationGifting && (
            <Body1
              className={style['transactional-mini-details-price']}
              data-testid={testId('product-price')}>
              ${selectedAmount}
            </Body1>
          )}
          {amountNeeded !== AMOUNT_NEEDED_UNAVAILABLE &&
            !isUsingDenominationGifting && (
              <Body1
                className={style['transactional-mini-details-price']}
                data-testid={testId('product-price')}>
                {formatPrice(giftCardAmount ?? price)}
              </Body1>
            )}
        </div>
      </div>

      <div
        data-testid="transactional-mini-details-actions"
        className={style['transactional-actions-success']}>
        <Button
          className={style['transactional-action-button']}
          color="secondary"
          onClick={() => handleContinueBrowsing()}
          size="lg"
          data-testid={testId('continue-browsing')}>
          Continue Browsing
        </Button>

        <Link
          href={cartUrl}
          className={style['transactional-action-button']}
          size="lg"
          data-testid={testId('view-cart')}>
          View Your Cart
        </Link>
      </div>
    </>
  );
};

AddToCartSuccess.defaultProps = {
  selectedVariant: null,
  giftCardAmount: null,
};

AddToCartSuccess.propTypes = {
  cartUrl: PropTypes.string.isRequired,
  testId: PropTypes.func.isRequired,
  product: Product.type.isRequired,
  giftCardAmount: PropTypes.number,
  selectedVariant: PropTypes.shape({
    sku: PropTypes.string,
    id: PropTypes.number,
    price: PropTypes.string,
  }),
};

export default AddToCartSuccess;
