import {
  GVR_REGISTRY_LIST_MANUAL_CLICK_SOURCE_ID,
  GVR_REGISTRY_LIST_PARTNER_CLICK_SOURCE_ID,
  SOURCE_AFFILIATE_ID_REGISTRY,
  SOURCE_AFFILIATE_ID_WWS,
  TRACK_HOST,
  WWS_REGISTRY_LIST_MANUAL_CLICK_SOURCE_ID,
  WWS_REGISTRY_LIST_PARTNER_CLICK_SOURCE_ID,
} from '../constants';

const clickSourceLookup = {
  manual: {
    [SOURCE_AFFILIATE_ID_REGISTRY]: GVR_REGISTRY_LIST_MANUAL_CLICK_SOURCE_ID,
    [SOURCE_AFFILIATE_ID_WWS]: WWS_REGISTRY_LIST_MANUAL_CLICK_SOURCE_ID,
  },
  view: {
    [SOURCE_AFFILIATE_ID_REGISTRY]: GVR_REGISTRY_LIST_PARTNER_CLICK_SOURCE_ID,
    [SOURCE_AFFILIATE_ID_WWS]: WWS_REGISTRY_LIST_PARTNER_CLICK_SOURCE_ID,
  },
};

const getTrackUrl = (manualRegistryUrl, params) => {
  const {registryId, retailerId, sourceAffiliateId} = params;
  const trackMethod = manualRegistryUrl ? 'manual' : 'view';
  const clickSourceId = clickSourceLookup[trackMethod][sourceAffiliateId];

  let trackingUrl = `${TRACK_HOST}/track/${trackMethod}/${clickSourceId}?r=${registryId}`;

  if (retailerId) {
    trackingUrl += `&rt=${retailerId}`;
  }

  return trackingUrl;
};

export default getTrackUrl;
