import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {shallow} from 'zustand/shallow';
import {AppContext} from './AppContext';
import {SortFiltersContext} from './SortFiltersContext';
import trackProductWishlistFiltered from '../../../analytics/trackProductWishlistFiltered';
import useGlobalProductStore from '../../../hooks/useGlobalProductStore';
import {useMember} from '../../../queries/member';
import {useCoupleSummary} from '../../../queries/couple';
import {useProducts} from '../../../queries/product';

export const ProductsContext = createContext({
  currentProduct: {},
  currentSortFilters: {},
  fetchProducts: () => {},
  isLoading: true,
  isFetched: false,
  hasFetchedInitialProducts: false,
  onClear: () => {},
  products: [],
  productsError: null,
  selectedFilterOptions: {},
  setCurrentProduct: () => {},
  setSelectedFilterOptions: () => {},
  updateProduct: () => {},
});

export const ProductsContextProvider = ({children}) => {
  const {memberId, coupleId} = useContext(AppContext);
  const {data: member} = useMember();
  const {data: {eventDate} = {}} = useCoupleSummary(memberId);
  const hasFetchedInitialProducts = useGlobalProductStore(
    (state) => state.hasDoneFirstProductsFetch,
    shallow,
  );
  const {
    initialFilters,
    initialFilterOptions,
    setCurrentSortFilters,
    setSelectedFilterOptions,
  } = useContext(SortFiltersContext);
  const [currentProduct, setCurrentProduct] = useState({});

  const {
    data: {products, hasTKRSProducts, hasAffiliateProducts} = {},
    isLoading,
    isFetched,
    error: productsError,
    refetch: fetchProducts,
  } = useProducts(memberId, coupleId);

  const updateProduct = async (sortFilters) => {
    if (sortFilters) {
      setCurrentSortFilters(sortFilters);
      trackProductWishlistFiltered({
        filters: sortFilters,
        memberId,
        member,
        eventDate,
      });
    } else {
      setSelectedFilterOptions(initialFilterOptions);
    }
  };

  const onClear = () => {
    setSelectedFilterOptions(initialFilterOptions);
    updateProduct(initialFilters);
  };

  return (
    <ProductsContext.Provider
      value={{
        currentProduct,
        fetchProducts,
        isLoading,
        isFetched,
        onClear,
        products,
        hasTKRSProducts,
        hasAffiliateProducts,
        setCurrentProduct,
        updateProduct,
        hasFetchedInitialProducts,
        productsError,
      }}>
      {children}
    </ProductsContext.Provider>
  );
};

ProductsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
