export const replaceAtIndex = (array, index, value) => {
  const copy = [...array];
  copy[index] = value;
  return copy;
};

export const replaceAtIndeces = (array, indeces, value) => {
  const copy = [...array];
  indeces.forEach((index) => {
    copy[index] = value;
  });
  return copy;
};
