import {
  RETAILER_PRODUCT,
  CASH_FUND,
  CASH_FUND_TYPES,
  TRANSACTIONAL_PRODUCT,
  DEFAULT_IMAGE_ID_PG,
  MEDIA_API_URL,
  UNIVERSAL_REGISTRY_PRODUCT,
  SUBTYPE_GIFT_CARD,
  SUBTYPE_EXPERIENCE,
  INTERNAL_GIFT_CARD,
} from '../constants';

export const isTKRSGiftCard = (registryItem) =>
  registryItem.type === INTERNAL_GIFT_CARD;

export const getBrand = (registryItem) => {
  if (registryItem.type === INTERNAL_GIFT_CARD) {
    return 'The Knot Registry Store';
  }

  return registryItem.brandName;
};

export const getName = (registryItem) => {
  if (registryItem.type === INTERNAL_GIFT_CARD) {
    return 'eGift Card';
  }

  return registryItem?.name?.replace(/\u00A0/g, ' ').replace(/&nbsp;/gi, ' ');
};

export const getPrice = (registryItem) => {
  if (registryItem.type === INTERNAL_GIFT_CARD) {
    const {min, max} = registryItem.allowedContributionRange;
    return `$${min}-$${max}`;
  }

  return registryItem.priceCents / 100;
};

export const getImage = (registryItem = {}) => {
  if (registryItem.imageUrl) {
    return registryItem.imageUrl;
  }

  if (registryItem.type === INTERNAL_GIFT_CARD) {
    return registryItem.galleryUrls[0];
  }

  if (registryItem.imageUrls?.length > 0) {
    if (
      [RETAILER_PRODUCT, UNIVERSAL_REGISTRY_PRODUCT].includes(registryItem.type)
    ) {
      return registryItem.imageUrls[0];
    }

    return `${MEDIA_API_URL}/fetch/${encodeURIComponent(
      registryItem.imageUrls[0],
    )}/transform~rs_w.256?fallback=${DEFAULT_IMAGE_ID_PG}`;
  }

  return `${MEDIA_API_URL}/${DEFAULT_IMAGE_ID_PG}`;
};

export const getStoreName = (registryItem) => {
  switch (registryItem.type) {
    case CASH_FUND:
      return 'Cash Fund';
    case RETAILER_PRODUCT:
      return registryItem.retailerName;
    case UNIVERSAL_REGISTRY_PRODUCT:
      return registryItem.storeName;
    case TRANSACTIONAL_PRODUCT:
      return registryItem.brandName;
    case INTERNAL_GIFT_CARD:
      return 'The Knot Registry Store';
    default:
      return '';
  }
};

export const isGiftCard = (registryItem) =>
  registryItem.subtype === SUBTYPE_GIFT_CARD;

export const isExperience = (registryItem) =>
  registryItem.subtype === SUBTYPE_EXPERIENCE;

export const isUnlimitedGiftCard = (itemAttributes = [], type) => {
  if (type !== RETAILER_PRODUCT) return false;

  return itemAttributes.some((attribute) => {
    const isAnyAmount =
      attribute.key === 'giftCardAmount' && attribute.value === 'Any Amount';

    const isUnfulfillable =
      attribute.key === 'isUnfulfillable' && attribute.value === true;

    return isAnyAmount || isUnfulfillable;
  });
};

export const isUnlimitedCashFund = (fundType, type) =>
  type === CASH_FUND && fundType === CASH_FUND_TYPES.UNLIMITED;

export const isTransactionalItemAvailable = (type, offers = []) =>
  type === TRANSACTIONAL_PRODUCT &&
  offers?.some((offer) => offer.active && !offer.deleted && offer.quantity > 0);
