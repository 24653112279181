import React, {useContext, useState} from 'react';
import {Column, Row} from '@xo-union/tk-component-grid';
import Select from './Select';
import {ProductsContext} from '../../contexts/ProductsContext';
import {SortFiltersContext} from '../../contexts/SortFiltersContext';
import {convertToDashCase} from '../../../../utils/string';
import style from './style.scss';

const SortSelect = () => {
  const {updateProduct} = useContext(ProductsContext);
  const {filterOptions, selectFilter, selectedFilterOptions} =
    useContext(SortFiltersContext);
  const [label, setLabel] = useState(selectedFilterOptions.sort.label);

  const selectedOption = filterOptions.sort.find(
    (option) => option.label === selectedFilterOptions.sort.label,
  );

  return (
    <Row
      className={style['sort-filter-bar-container']}
      data-testid="product-grid-sort-filter-bar"
      hGutters={false}>
      <Column className={style['filter-container']} role="group">
        <Select
          data-testid="product-grid-sort-select"
          label="Sort By"
          value={selectedOption.label}
          onChange={(optionLabel) => {
            selectFilter('sort', optionLabel, updateProduct);
            setLabel(label);
          }}>
          {filterOptions.sort.map((sortOption) => {
            const isSelected =
              sortOption.label === selectedFilterOptions.sort.label;
            let dataTestId = `product-grid-sort-filter-bar-sort-option-${convertToDashCase(
              sortOption.label,
            )}`;

            if (isSelected) dataTestId += '-is-selected';

            return (
              <option
                data-testid={dataTestId}
                key={sortOption.label}
                value={sortOption.label}>
                {sortOption.label}
              </option>
            );
          })}
        </Select>
      </Column>
    </Row>
  );
};

export default SortSelect;
