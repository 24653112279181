import mapProductForAnalytics from './helpers/mapProductForAnalytics';
import {formatDate} from '../utils/analytics';

const trackProductWishlistViewed = ({
  memberId,
  member,
  weddingDate,
  products,
}) =>
  window.analytics.track({
    event: 'Product Wishlist Viewed',
    properties: {
      wishlist_id: memberId,
      wishlist_name: 'Transactional Registry',
      list_id: 'guest-registry',
      memberId: member?.id || null,
      event_date: formatDate(weddingDate),
      currency: 'USD',
      products: products.map((product) =>
        mapProductForAnalytics(product, product.position),
      ),
    },
  });
export default trackProductWishlistViewed;
