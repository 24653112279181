import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import UnionProgressBar from '@xo-union/tk-component-progress-bar';
import style from './style.scss';

const ProgressBar = ({progress, isLarge}) => {
  const unionProgress = progress / 100;
  return (
    <div
      data-testid="group-gifting-progress"
      className={cx(style.wrapper, {
        [style.large]: isLarge,
      })}>
      <UnionProgressBar progress={unionProgress} />
    </div>
  );
};

ProgressBar.defaultProps = {
  isLarge: false,
  progress: 30,
};

ProgressBar.propTypes = {
  isLarge: PropTypes.bool,
  progress: PropTypes.number,
};

export default ProgressBar;
