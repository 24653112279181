import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import analytics from '@xo-union/tk-analytics';
import {useMobileMedia} from '@xo-union/tk-ui-breakpoints';
import {DisplayLink} from '@xo-union/tk-ui-links';
import style from './style.scss';

const formatAsHttps = (url) =>
  (url.match('theknot.com')
    ? `https://${url.replace(/^https?:?\/\//, '')}`
    : url
  ).replace('www.www.', 'www.');

const WeddingWebsite = ({websiteUrl}) => {
  const isMobileDevice = useMobileMedia().yes;

  const handleClick = () => {
    analytics.track('Click-through to Wedding Website', {
      platform: isMobileDevice ? 'mobile' : 'web',
      product: 'guest facing registry',
      websiteExists: true,
    });
  };

  return (
    <DisplayLink
      className={cx(style['wedding-website'])}
      href={formatAsHttps(websiteUrl)}
      onClick={handleClick}
      rel="noopener noreferrer"
      target="_blank">
      View Our Wedding Website
    </DisplayLink>
  );
};

WeddingWebsite.propTypes = {
  websiteUrl: PropTypes.string.isRequired,
};

export default WeddingWebsite;
