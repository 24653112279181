import mapFilters from './helpers/mapFilters';
import {formatDate} from '../utils/analytics';

const trackProductWishlistFiltered = ({
  filters,
  memberId,
  member,
  eventDate,
}) => {
  window.analytics.track({
    event: 'Wishlist Product Filtered',
    properties: {
      wishlist_id: memberId,
      list_id: 'guest-registry',
      memberId: member?.id || null,
      currency: 'USD',
      event_date: eventDate ? formatDate(eventDate) : undefined,
      wishlist_name: 'The Knot Wedding Registry',
      ...mapFilters(filters),
    },
  });
};

export default trackProductWishlistFiltered;
