import {
  AMOUNT_NEEDED_PURCHASED,
  AMOUNT_NEEDED_UNAVAILABLE,
  AMOUNT_NEEDED_UNLIMITED,
  INTERNAL_GIFT_CARD,
  TRANSACTIONAL_PRODUCT,
} from '../../../constants';
import {
  isUnlimitedCashFund,
  isUnlimitedGiftCard,
  isTransactionalItemAvailable,
} from '../../../utils/registryItem';

const getNeedsText = (numReceived, numRequested) => {
  return `Needs ${Math.max(0, numRequested - numReceived)} of ${numRequested}`;
};
const getAmountNeeded = ({
  fulfilled = false,
  fundType = '',
  isAvailable,
  itemAttributes = [],
  numReceived,
  numRequested,
  offers = [],
  priceCents,
  type,
  allowedContributionRange,
}) => {
  let amountNeeded =
    isUnlimitedCashFund(fundType, type) ||
    isUnlimitedGiftCard(itemAttributes, type)
      ? AMOUNT_NEEDED_UNLIMITED
      : getNeedsText(numReceived, numRequested);
  let isUnavailable = false;
  /*
    `isAvailable === false` because it can be `undefined`, which
    should not be treated the same as `false` for business logic.
  */

  if (
    isAvailable === false ||
    (type === TRANSACTIONAL_PRODUCT &&
      !isTransactionalItemAvailable(type, offers))
  ) {
    amountNeeded = AMOUNT_NEEDED_UNAVAILABLE;
    isUnavailable = true;
  }

  if (fulfilled) {
    amountNeeded = AMOUNT_NEEDED_PURCHASED;
    isUnavailable = true;
  }

  let priceNeeded;

  if (type === INTERNAL_GIFT_CARD) {
    const {min, max} = allowedContributionRange;
    priceNeeded = `${min}-$${max}`;
  } else {
    priceNeeded = (priceCents / 100).toFixed(2);
  }

  return {
    amountNeeded,
    isUnavailable,
    priceNeeded,
  };
};

export default getAmountNeeded;
