import React, {useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import Product from '../../../../proptypes/Product';
import {AppContext} from '../../contexts/AppContext';
import {ModalContext} from '../../contexts/ModalContext';
import {ProductsContext} from '../../contexts/ProductsContext';
import getRegistry from '../../utils/getRegistry';
import getTrackUrl from '../../utils/getTrackUrl';
import {onEnter} from '../../../../utils/event';
import trackProductCardClick from '../../utils/analytics/trackProductCardClick';
import trackWishListProductClicked from '../../../../analytics/trackWishListProductClicked';
import {useCoupleSummary} from '../../../../queries/couple';
import {RETAILER_PRODUCT, FIRST_CASH_FUND_ID} from '../../../../constants';
import ProductCard from './ProductCard';
import style from './style.scss';
import ProductModal from '../ProductModal';
import AffiliateModal from '../UniversalContent/components/AffiliateModal';
import {useMember} from '../../../../queries/member';
import useProductModalConfig from '../ProductModal/ProductModalConfig';
import useImpressionObserver from '../../../../hooks/useImpressionObserver';

// eslint-disable-next-line
const Tag = ({element, children, ...props}) =>
  React.createElement(element, props, children);
const ProductCardWrapper = ({position, product, isFirstCashFund}) => {
  const {
    setScrollPosition,
    application,
    isMobile,
    memberId,
    coupleId,
    sourceAffiliateId,
  } = useContext(AppContext);
  const cardRef = useRef();
  const {data: {registries, eventDate} = {}} = useCoupleSummary(
    memberId || coupleId,
  );
  const {data: member} = useMember();
  const {toggleModal, setModalHeader} = useContext(ModalContext);
  const productModalConfig = useProductModalConfig();
  const {setCurrentProduct} = useContext(ProductsContext);
  const registry = getRegistry(product, registries);
  const trackUrl = getTrackUrl(sourceAffiliateId, product, registry);
  // Note: this is the URL that we must use in the affiliate modal links
  const href =
    product.type === RETAILER_PRODUCT ? trackUrl : product.productUrl;

  useImpressionObserver({product, position, cardRef});

  const handleOpenModal = () => {
    setCurrentProduct({
      ...product,
      position,
      trackUrl,
    });
  };

  const handleAffiliateClick = () => {
    toggleModal({
      isVisible: true,
      props: productModalConfig.props,
      content: <AffiliateModal />,
    });
    setCurrentProduct({
      ...product,
      position,
      href,
    });
  };

  const handleCardClick = () => {
    if (product.type === RETAILER_PRODUCT) {
      trackWishListProductClicked({
        memberId,
        member,
        product,
        eventDate,
        position,
      });
      handleAffiliateClick();
      return;
    }

    setScrollPosition(window.scrollY);
    setModalHeader(null);

    trackProductCardClick({isMobile, application, product, registry, memberId});
    trackWishListProductClicked({
      memberId,
      member,
      product,
      eventDate,
      position,
    });

    if (product.type !== RETAILER_PRODUCT) {
      toggleModal({
        isVisible: true,
        props: productModalConfig.props,
        content: <ProductModal />,
      });
    }

    handleOpenModal();
  };

  return (
    !!registry && (
      <div
        ref={cardRef}
        aria-label="product"
        data-testid={`product-card-id-${product.id}`}
        className={style['product-card-container']}
        role="group"
        id={isFirstCashFund ? FIRST_CASH_FUND_ID : undefined}>
        <Tag
          element="div"
          role="link"
          className={style['product-card']}
          data-tracking-url={trackUrl}
          onClick={handleCardClick}
          onKeyUp={onEnter(handleCardClick)}
          tabIndex={0}>
          <ProductCard
            product={product}
            position={position}
            onClick={handleCardClick}
          />
        </Tag>
      </div>
    )
  );
};

ProductCardWrapper.propTypes = {
  position: PropTypes.number.isRequired,
  product: Product.type.isRequired,
  isFirstCashFund: PropTypes.bool.isRequired,
};

export default ProductCardWrapper;
