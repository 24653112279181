import React from 'react';
import {Body1} from '@xo-union/tk-ui-typography';
import PropTypes from 'prop-types';
import styles from './index.scss';

const MarkUnitAsFulfilledDialogContent = ({
  headerText,
  contentText,
  footer,
}) => {
  return (
    <Body1 className={styles['dialog-container']}>
      <Body1 className={styles['dialog-header']}>{headerText}</Body1>
      <Body1 className={styles['dialog-content']}>{contentText}</Body1>
      {footer && <Body1 className={styles['dialog-footer']}>{footer}</Body1>}
    </Body1>
  );
};

MarkUnitAsFulfilledDialogContent.defaultProps = {
  headerText: '',
  contentText: '',
  footer: null,
};

MarkUnitAsFulfilledDialogContent.propTypes = {
  headerText: PropTypes.string,
  contentText: PropTypes.string,
  footer: PropTypes.node,
};

export default MarkUnitAsFulfilledDialogContent;
