import {cloneDeep} from 'lodash-es';
import {
  CASH_REGISTRY_TYPE,
  UNIVERSAL_REGISTRY_RETAILER_ID,
  DEFAULT_MAX_SORT_ORDER,
} from '../constants';

const isNonCashRegistry = (registry) =>
  registry?.registryType !== CASH_REGISTRY_TYPE;

export const shouldShowRegistries = (
  registries,
  initialItems = [],
  hasTransactionalRegistry,
) =>
  !hasTransactionalRegistry && // short-circuit for hybrid registries
  !(!registries?.some(isNonCashRegistry) && initialItems.length === 0);

export const getShowCashRegistry = (couple = {}) => {
  const {isCashRegistryActive, stripeAccountStatus} = couple;

  return !!isCashRegistryActive && stripeAccountStatus === 'verified';
};

export const isNotHiddenCashFund = (registryType, showCashRegistry = false) =>
  registryType !== CASH_REGISTRY_TYPE ||
  (registryType === CASH_REGISTRY_TYPE && showCashRegistry);

export const getShownRegistries = (
  registries = [],
  showCashRegistry = false,
) => {
  const filter = (registry) => {
    if (registry.hiddenWws || registry.hiddenCoupleSearch) {
      return false;
    }

    const {manualRegistryName, retailer, registryType} = registry;
    const retailerId =
      retailer && retailer.id ? retailer.id : registry.retailerId;

    if (!retailerId && !manualRegistryName) return false;
    if (!retailerId && manualRegistryName) return true;

    return (
      retailerId !== UNIVERSAL_REGISTRY_RETAILER_ID &&
      isNotHiddenCashFund(registryType, showCashRegistry)
    );
  };

  return registries.filter(filter);
};

export const sortRegistries = (registries) => {
  const oneColSortOrders = registries.map(
    (registry) => (registry.retailer && registry.retailer.oneColSortOrder) || 0,
  );
  const maxOneColSortOrder = Math.max(...oneColSortOrders);

  return registries
    .map((registry) => {
      const registryItem = cloneDeep(registry);
      const {retailer} = registry;

      if (retailer && retailer.isPartner && !retailer.isDeleted) {
        registryItem.sortOrder =
          registry.retailer.oneColSortOrder || maxOneColSortOrder + 1;
      } else {
        registryItem.sortOrder = DEFAULT_MAX_SORT_ORDER;
      }

      return registryItem;
    })
    .sort((a, b) => a.sortOrder - b.sortOrder);
};
