import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {compose} from '@xo-union/react-css-modules';
import {StatusBadge} from '@xo-union/tk-component-badges';
import {Body1} from '@xo-union/tk-ui-typography';
import makeTestId from '../../utils/makeTestId';
import {
  AMOUNT_NEEDED_PURCHASED,
  AMOUNT_NEEDED_UNAVAILABLE,
} from '../../../../constants';
import style from './style.scss';

const getBadgeType = (amountNeeded) => {
  switch (amountNeeded) {
    case AMOUNT_NEEDED_PURCHASED: {
      return 'positive';
    }
    default:
      return 'informational';
  }
};

const AmountNeeded = ({amountNeeded, className}) => {
  const isPurchased = amountNeeded === AMOUNT_NEEDED_PURCHASED;
  const isUnavailable = amountNeeded === AMOUNT_NEEDED_UNAVAILABLE;

  if (isPurchased || isUnavailable)
    return (
      <StatusBadge
        classes={compose({container: cx(className, style['status-badge'])})}
        size="lg"
        type={getBadgeType(amountNeeded)}>
        {amountNeeded}
      </StatusBadge>
    );

  return (
    <Body1
      className={cx(className, style['amount-needed'])}
      data-testid={makeTestId('product-grid', 'amount-needed')}>
      {amountNeeded}
    </Body1>
  );
};

AmountNeeded.propTypes = {
  amountNeeded: PropTypes.string.isRequired,
  className: PropTypes.string,
};

AmountNeeded.defaultProps = {
  className: '',
};

export default AmountNeeded;
