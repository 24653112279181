import {find} from 'lodash-es';
import {
  CASH_FUND,
  INTERNAL_GIFT_CARD,
  TRANSACTIONAL_PRODUCT,
} from '../../../constants';

const getRegistry = (product, registries) => {
  if (
    [CASH_FUND, TRANSACTIONAL_PRODUCT, INTERNAL_GIFT_CARD].includes(
      product.type,
    )
  )
    // set to empty but truthy so that it still technically has a registry for rendering logic
    return {};

  const registryId = product.registryId.toLowerCase();

  return find(
    registries,
    (r) => !!r.registryUuid && r.registryUuid.toLowerCase() === registryId,
  );
};

export default getRegistry;
