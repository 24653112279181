// IAB specifications
// >=50% in viewport for 1+ seconds
const threshold = 0.5;
const minimumVisibleTime = 1000;

const setIntersectionObserver = ({callback, element, eventData}) => {
  if (!element.current) return {};

  let intersectionHandled = false;
  let visibilityTimer;

  const handleIntersection = (observer) => {
    intersectionHandled = true;

    // Take the action now that the element is in view
    callback({eventData});

    // Cleanup observer since we only need to act on the visible intersection once
    if (observer?.disconnect) {
      observer.disconnect();
    }
  };

  const onIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !intersectionHandled) {
        visibilityTimer = setTimeout(() => {
          handleIntersection(observer);
        }, minimumVisibleTime);
      }

      if (visibilityTimer && !entry.isIntersecting && !intersectionHandled) {
        // timer cleanup if element scrolls out of view before minimumVisibleTime
        clearTimeout(visibilityTimer);
      }
    });
  };

  const intersectionObserver = new IntersectionObserver(onIntersection, {
    threshold,
  });

  intersectionObserver.observe(element.current);

  // Allow observer cleanup at react layer for unmounts
  return intersectionObserver;
};

export default setIntersectionObserver;
