import React from 'react';
import PropTypes from 'prop-types';
import Products from './components/Products';
import Product from '../../proptypes/Product';
import PageLoaded from '../PageLoaded';
import {AppContextProvider} from './contexts/AppContext';
import {ErrorContextProvider} from './contexts/ErrorContext';
import {ModalProvider} from './contexts/ModalContext';
import {ProductsContextProvider} from './contexts/ProductsContext';
import {SortFiltersContextProvider} from './contexts/SortFiltersContext';
import {
  APPLICATION_GVR,
  APPLICATION_WWS,
  APPLICATION_WWS_OLD_TEMPLATE,
} from '../../constants';
import {MarkUnitAsFulfilledContextProvider} from './contexts/MarkUnitAsFulfilledContext';
import {SnackbarContextProvider} from './contexts/SnackbarContext';
import {DialogContextProvider} from './contexts/DialogContext';

const ProductGrid = ({
  application,
  cartRedirectPath,
  children,
  isMobile,
  memberId,
  coupleId,
  products: initialProducts,
  stores: initialStores,
}) => {
  return (
    <AppContextProvider
      application={application}
      cartRedirectPath={cartRedirectPath}
      initialProducts={initialProducts}
      initialStores={initialStores}
      isMobile={isMobile}
      memberId={memberId}
      coupleId={coupleId}>
      <ErrorContextProvider>
        <SortFiltersContextProvider>
          <ProductsContextProvider>
            <PageLoaded memberId={memberId}>
              <MarkUnitAsFulfilledContextProvider>
                <DialogContextProvider>
                  <SnackbarContextProvider>
                    <ModalProvider>
                      <Products>{children}</Products>
                    </ModalProvider>
                  </SnackbarContextProvider>
                </DialogContextProvider>
              </MarkUnitAsFulfilledContextProvider>
            </PageLoaded>
          </ProductsContextProvider>
        </SortFiltersContextProvider>
      </ErrorContextProvider>
    </AppContextProvider>
  );
};

ProductGrid.propTypes = {
  application: PropTypes.oneOf([
    APPLICATION_GVR,
    APPLICATION_WWS,
    APPLICATION_WWS_OLD_TEMPLATE,
  ]).isRequired,
  cartRedirectPath: PropTypes.string,
  children: PropTypes.node,
  isMobile: PropTypes.bool,
  memberId: PropTypes.string,
  coupleId: PropTypes.string,
  products: PropTypes.arrayOf(Product.type),
  stores: PropTypes.arrayOf(PropTypes.shape({})),
};

ProductGrid.defaultProps = {
  cartRedirectPath: '',
  children: null,
  isMobile: undefined,
  memberId: '',
  coupleId: '',
  products: [],
  stores: [],
};

export default ProductGrid;
