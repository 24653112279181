import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, MultiSelectGroup} from '@xo-union/tk-component-switches';
import {ProductsContext} from '../../contexts/ProductsContext';
import {SortFiltersContext} from '../../contexts/SortFiltersContext';
import getArrayOfLabels from '../../utils/getArrayOfLabels';
import makeTestId from '../../utils/makeTestId';

const MultiSelectFilter = ({testId, type}) => {
  const {updateProduct} = useContext(ProductsContext);
  const {filterOptions, selectFilter, selectedFilterOptions} =
    useContext(SortFiltersContext);

  return (
    <MultiSelectGroup
      onChange={(e) => selectFilter(type, e.target.value, updateProduct)}
      values={getArrayOfLabels(selectedFilterOptions[type])}>
      {getArrayOfLabels(filterOptions[type]).map((label) => (
        <Checkbox
          data-testid={makeTestId(testId, `${type}-option-${label}`)}
          key={label}
          value={label}>
          {label}
        </Checkbox>
      ))}
    </MultiSelectGroup>
  );
};

MultiSelectFilter.defaultProps = {
  testId: '',
};

MultiSelectFilter.propTypes = {
  testId: PropTypes.string,
  type: PropTypes.oneOf(['price', 'status', 'stores']).isRequired,
};

export default MultiSelectFilter;
