import React, {createContext, useRef} from 'react';
import PropTypes from 'prop-types';
import {Snackbar} from '@xo-union/tk-component-alerts';

export const SnackbarContext = createContext({});

export const SnackbarContextProvider = ({children}) => {
  const snackbar = useRef(null);

  const showSnackbar = ({data, dismissTimeout} = {}) => {
    snackbar.current.showAndDismiss({
      data,
      dismissTimeout: dismissTimeout || 5000,
    });
  };

  const disMissSnackbar = () => {
    snackbar.current.dismiss();
  };

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
        disMissSnackbar,
      }}>
      <Snackbar ref={snackbar} />
      {children}
    </SnackbarContext.Provider>
  );
};

SnackbarContextProvider.propTypes = {
  children: PropTypes.node,
};

SnackbarContextProvider.defaultProps = {
  children: null,
};
