import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import analytics from '@xo-union/tk-analytics';
import {Body1} from '@xo-union/tk-ui-typography';
import {
  APPLICATION_GVR,
  APPLICATION_WWS,
  APPLICATION_WWS_OLD_TEMPLATE,
  FEED_REGISTRY_TYPE,
  SOURCE_AFFILIATE_ID_REGISTRY,
  SOURCE_AFFILIATE_ID_WWS,
} from '../../constants';
import getTrackUrl from '../../utils/getTrackUrl';
import getWwsTempVersion from '../../utils/getWwsTempVersion';
import isWeddingWebsite from '../../utils/isWeddingWebsite';
import image from './store.png';
import style from './style.scss';

const NonPartnerItem = ({
  application,
  className,
  isMobile,
  memberId,
  registry,
  sourceAffiliateId,
}) => {
  const {
    manualRegistryName: name,
    manualRegistryUrl,
    id: registryId,
    registryType,
  } = registry;
  const trackingUrl = getTrackUrl(manualRegistryUrl, {
    registryId,
    sourceAffiliateId,
  });
  const charLimit = 40;
  const truncatedName =
    name.length > charLimit ? `${name.substring(0, charLimit)}...` : name;

  const handleClick = (e) => {
    e.preventDefault();

    const defaultParams = {
      platform: isMobile ? 'mobile' : 'web',
      product: isWeddingWebsite(application)
        ? APPLICATION_WWS
        : APPLICATION_GVR,
      wwsTempVersion: getWwsTempVersion(application),
    };

    if (memberId) {
      defaultParams.memberId = memberId;
    }

    analytics.track('Click-through to Retailer', {
      ...defaultParams,
      registryType:
        parseInt(registryType, 10) === FEED_REGISTRY_TYPE ? 'linked' : 'manual',
      retailer: name,
      source: 'retailer icon',
    });

    window.open(trackingUrl, '_blank');
  };

  return (
    <div
      data-testid={`manual-registry-card-${registryId}`}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}>
      <div
        className={cx(style['non-partner-item-container'], className)}
        style={{
          background: `url(${image}) left no-repeat`,
        }}>
        <Body1 className={style['retailer-name']} alt={name} bold>
          {truncatedName}
        </Body1>

        <a
          color="primary"
          href={trackingUrl}
          rel="noopener noreferrer"
          size="md"
          target="_blank">
          Shop This Registry
        </a>
      </div>
    </div>
  );
};

NonPartnerItem.propTypes = {
  application: PropTypes.oneOf([
    APPLICATION_GVR,
    APPLICATION_WWS,
    APPLICATION_WWS_OLD_TEMPLATE,
  ]).isRequired,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  memberId: PropTypes.string,
  registry: PropTypes.shape({
    id: PropTypes.string.isRequired,
    manualRegistryName: PropTypes.string.isRequired,
    manualRegistryUrl: PropTypes.string.isRequired,
    registryType: PropTypes.number.isRequired,
  }).isRequired,
  sourceAffiliateId: PropTypes.oneOf([
    SOURCE_AFFILIATE_ID_REGISTRY,
    SOURCE_AFFILIATE_ID_WWS,
  ]).isRequired,
};

NonPartnerItem.defaultProps = {
  className: '',
  isMobile: undefined,
  memberId: '',
};

export default NonPartnerItem;
