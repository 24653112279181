import {useContext} from 'react';
import {compose} from '@xo-union/react-css-modules';
import styles from './styles.scss';
import {ModalContext} from '../../contexts/ModalContext';

const useProductModalConfig = () => {
  const {onClose} = useContext(ModalContext);

  const modalClasses = compose({
    'button-container': styles['button-container'],
    'close-button': styles['close-button'],
    large: styles['modal-spacing'],
  });

  return {
    isVisible: true,
    props: {
      onClose,
      classes: modalClasses,
      size: 'lg',
      'aria-describedby': 'product-details-name',
      'data-testid': 'product-grid-product-modal',
      role: 'dialog',
      'aria-modal': 'true',
    },
  };
};

export default useProductModalConfig;
