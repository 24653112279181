import {wishlistProductAddedToCart} from '.';
import {coalesceFalsyObjectValuesToNull, formatDate} from '../utils/analytics';
import mapProduct from './helpers/mapProduct';
import getStockStatus from '../components/ProductGrid/utils/getStockStatus';

const trackProductAdded = ({
  cartId,
  memberId,
  member,
  product,
  quantity,
  position = null,
  selectedVariant = null,
  giftCardAmount = null,
}) => {
  const mappedProduct = mapProduct(
    product,
    quantity,
    selectedVariant,
    giftCardAmount,
  );

  if (memberId) {
    wishlistProductAddedToCart(
      coalesceFalsyObjectValuesToNull({
        cart_id: cartId,
        wishlist_id: memberId,
        list_id: 'guest-registry',
        currency: 'USD',
        memberId: member?.id || null,
        wishlist_name: 'Transactional Registry',
        ...mappedProduct,
        position: position || mappedProduct.position,
        date_added_to_wishlist: (() => {
          const createdAt = product.createdAt || product.timestamp?.createdAt;
          return createdAt ? formatDate(createdAt) : undefined;
        })(),
        couple_customization: {
          coupleNote: product.coupleNote,
          topChoice: product.topChoice,
        },
        stock_status: getStockStatus(product),
        average_rating: product.reviews?.avgRating || null,
        affiliation: product.affiliation || null,
      }),
    );
  }
};

export default trackProductAdded;
