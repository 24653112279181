import React, {useContext} from 'react';
import FilterPill from './FilterPill';
import {ProductsContext} from '../../contexts/ProductsContext';
import {SortFiltersContext} from '../../contexts/SortFiltersContext';
import getLabels from '../../utils/getArrayOfLabels';
import {onEnter} from '../../../../utils/event';
import style from './style.scss';

const FilterPills = () => {
  const {updateProduct} = useContext(ProductsContext);
  const {
    clearFilters,
    selectFilter,
    selectedFilterOptions: options,
  } = useContext(SortFiltersContext);
  const labels = [
    ...getLabels(options.price),
    ...getLabels(options.status),
    ...getLabels(options.stores),
  ];

  return (
    <>
      {labels.length > 0 && (
        <div
          className={style['filter-pills-container']}
          data-testid="filter-pills-container">
          {getLabels(options.price).map((option) => (
            <FilterPill
              key={option}
              label={option}
              onClick={() => selectFilter('price', option, updateProduct)}
            />
          ))}

          {getLabels(options.stores).map((option) => (
            <FilterPill
              key={option}
              label={option}
              onClick={() => selectFilter('stores', option, updateProduct)}
            />
          ))}

          {getLabels(options.status).map((option) => (
            <FilterPill
              key={option}
              label={option}
              onClick={() => selectFilter('status', option, updateProduct)}
            />
          ))}

          {labels.length > 1 && (
            <div
              aria-label="Clear all selected filters"
              className={style['filter-pills-clear-button']}
              data-testid="filter-pills-clear-button"
              onClick={() =>
                clearFilters(['price', 'stores', 'status'], updateProduct)
              }
              onKeyDown={() =>
                onEnter(
                  clearFilters(['price', 'stores', 'status'], updateProduct),
                )
              }
              size="lg"
              role="button"
              tabIndex="0">
              Clear All
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FilterPills;
