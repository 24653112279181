const getStockStatus = (product) => {
  if (product.discontinued) {
    return 'discontinued';
  }
  if (product.offers?.[0]?.quantity <= 0) {
    return 'out of stock';
  }
  return 'in stock';
};

export default getStockStatus;
