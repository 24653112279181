import React, {useContext, useState} from 'react';
import Honeybadger from '@honeybadger-io/js';
import {getCartUrl, useUpdateCartCount} from '@tkww/reg-shared-ui-cart-icon';
import {AppContext} from '../components/ProductGrid/contexts/AppContext';
import {ErrorContext} from '../components/ProductGrid/contexts/ErrorContext';
import {ModalContext} from '../components/ProductGrid/contexts/ModalContext';
import {MODAL_SIZES} from '../constants';
import {useAddItemToCart} from '../mutations/magento';
import AddToCartSuccess from '../components/ProductGrid/components/TransactionalContent/components/AddToCartSuccess';
import trackProductAdded from '../analytics/trackProductAdded';
import {useMember} from '../queries/member';
import {getPrice} from '../utils/registryItem';

const useAddToCart = ({testId = ''}) => {
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const {memberId} = useContext(AppContext);
  const {data: member} = useMember();
  const {setModalError} = useContext(ErrorContext);
  const {setModalHeader, setModalSize, setProps, props, toggleModal} =
    useContext(ModalContext);
  const {mutateAsync: addItemToCart} = useAddItemToCart();

  const updateCartCount = useUpdateCartCount();

  const addToCart = async ({
    product,
    selectedVariant = null,
    quantity = 1,
    giftCardAmount = null,
    position = null,
  }) => {
    setIsAddingToCart(true);
    const productOfferId = product?.offers?.[0]?.offerId;

    try {
      const cartId = await addItemToCart({
        sku: selectedVariant?.sku || product.sku,
        quantity,
        offerId: selectedVariant?.id || productOfferId,
        giftCardAmount,
        price: selectedVariant
          ? parseInt(selectedVariant.price, 10)
          : getPrice(product),
        product,
      });
      trackProductAdded({
        cartId,
        memberId,
        member,
        product,
        quantity,
        position,
        selectedVariant,
        giftCardAmount,
      });
      setIsAddingToCart(false);
      setModalError('');

      updateCartCount({cartId, quantity});

      setModalSize(MODAL_SIZES.SMALL);
      setProps({...props, size: 'sm'});
      setModalHeader('Added to Your Cart');

      toggleModal({
        isVisible: true,
        content: (
          <AddToCartSuccess
            selectedVariant={selectedVariant}
            cartUrl={getCartUrl({withRedirect: true, cartId})}
            testId={testId}
            product={product}
            giftCardAmount={giftCardAmount}
          />
        ),
      });
    } catch (error) {
      setIsAddingToCart(false);
      setModalError(
        error?.toString()?.replace(/^Error:\s/, '') ||
          "Oops! We're having trouble adding this item to your cart. Please try again.",
      );
      Honeybadger.notify(error, 'AddToCartError');
    }
  };

  return {addToCart, isAddingToCart};
};

export default useAddToCart;
