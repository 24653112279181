import {useState, useContext} from 'react';
import {AppContext} from '../components/ProductGrid/contexts/AppContext';
import {ErrorContext} from '../components/ProductGrid/contexts/ErrorContext';
import {UNIVERSAL_STEPS, MODAL_SIZES} from '../constants';
import {MarkUnitAsFulfilledContext} from '../components/ProductGrid/contexts/MarkUnitAsFulfilledContext';

export default () => {
  const [visible, toggleVisible] = useState(false);
  const [content, setContent] = useState(null);
  const [props, setProps] = useState();
  const [modalHeader, setModalHeader] = useState('');
  const [modalSize, setModalSize] = useState(MODAL_SIZES.LARGE);
  const [universalStep, setUniversalStep] = useState(
    UNIVERSAL_STEPS.PRODUCT_DETAILS,
  );
  const {scrollToLastProduct} = useContext(AppContext);
  const {setModalError} = useContext(ErrorContext);
  const {initialMarkFulfilledInfo} = useContext(MarkUnitAsFulfilledContext);

  const toggleModal = ({
    content: modalContent = false,
    props: modalProps = false,
    isVisible = !visible,
  } = {}) => {
    if (modalContent) {
      setContent(modalContent);
    }
    if (modalProps) {
      setProps(modalProps);
    }
    return toggleVisible(isVisible);
  };

  const onClose = () => {
    toggleModal({isVisible: false});
    setModalHeader('');
    setModalError('');
    scrollToLastProduct();
    setUniversalStep(UNIVERSAL_STEPS.PRODUCT_DETAILS);
    initialMarkFulfilledInfo();
  };

  return {
    visible,
    toggleModal,
    content,
    props,
    modalHeader,
    setModalHeader,
    universalStep,
    setUniversalStep,
    modalSize,
    setModalSize,
    onClose,
    setProps,
  };
};
