import React from 'react';
import PropTypes from 'prop-types';
import {onEnter} from '../../../../utils/event';

const RetailerLinkWrapper = ({
  children,
  className,
  isCashRegistry,
  isPartnerRegistry,
  onClick,
  registryId,
  retailerId,
  source,
  trackingUrl,
}) => {
  if (isCashRegistry)
    return (
      <div
        className={className}
        data-testid={`${source}-cash-registry-card-${retailerId}`}
        onClick={onClick}
        onKeyUp={onEnter(onClick)}
        role="link"
        tabIndex={0}>
        {children}
      </div>
    );

  return (
    <div
      className={className}
      data-testid={
        isPartnerRegistry
          ? `${source}-registry-card-${retailerId}`
          : `${source}-manual-registry-card-${registryId}`
      }
      href={trackingUrl}
      onClick={onClick}
      onKeyUp={onEnter(onClick)}
      rel="noopener noreferrer"
      role="link"
      tabIndex={0}
      target="_blank">
      {children}
    </div>
  );
};

const CashRetailerLinkShape = PropTypes.shape({
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isCashRegistry: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  retailerId: PropTypes.number.isRequired,
  source: PropTypes.string.isRequired,
});

const ManualOrPartnerRetailerLinkShape = PropTypes.shape({
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isPartnerRegistry: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  registryId: PropTypes.string.isRequired,
  retailerId: PropTypes.number.isRequired,
  source: PropTypes.string.isRequired,
  trackingUrl: PropTypes.string.isRequired,
});

RetailerLinkWrapper.propTypes = PropTypes.oneOfType([
  CashRetailerLinkShape,
  ManualOrPartnerRetailerLinkShape,
]).isRequired;

export default RetailerLinkWrapper;
