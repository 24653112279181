/* eslint-disable import/prefer-default-export */
import {TRANSACTIONAL_PRODUCT, RETAILER_PRODUCT} from '../constants';
import {isTransactionalItemAvailable} from './registryItem';
import isWeddingWebsite from './isWeddingWebsite';

export const coalesceFalsyObjectValuesToNull = (obj) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: Number.isInteger(value) ? value : value || null,
    }),
    {},
  );

export const getListName = (application) =>
  isWeddingWebsite(application) ? 'plp-gvr-attached' : 'plp-gvr-unattached';

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const getGiftStatus = (currentProduct) => {
  const {
    type,
    offers = [],
    fulfilled,
    isFulfilled,
    isAvailable,
  } = currentProduct;

  let isOutOfStock = false;

  if (type === TRANSACTIONAL_PRODUCT) {
    isOutOfStock = !isTransactionalItemAvailable(type, offers);
  } else if (type === RETAILER_PRODUCT) {
    isOutOfStock = isAvailable;
  }

  if (isOutOfStock) {
    return 'out of stock';
  }

  if (fulfilled || isFulfilled) {
    return 'fulfilled';
  }

  return 'available';
};
