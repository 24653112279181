import {useState, useEffect} from 'react';
import mapProductForAnalytics from './helpers/mapProductForAnalytics';
import {formatDate} from '../utils/analytics';
import {useCoupleSummary} from '../queries/couple';

const useTrackWishListProductViewed = ({memberId, member, product, index}) => {
  const {data: coupleSummary = {}} = useCoupleSummary(memberId);
  const [flag, setFlag] = useState(false);
  const [tracked, setTracked] = useState(false);

  window.analytics.ready(() => {
    setFlag(true);
  });

  useEffect(() => {
    if (flag && !tracked) {
      window.analytics.track({
        event: 'Wishlist Product Viewed',
        properties: {
          wishlist_id: memberId,
          memberId: member?.id || null,
          currency: 'USD',
          wishlist_name: 'Transactional Registry',
          list_id: 'guest-registry',
          event_date: formatDate(coupleSummary.eventDate) || null,
          ...mapProductForAnalytics(product, index),
        },
      });
      setTracked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, tracked]);
};

export default useTrackWishListProductViewed;
