import {atom, useAtom} from 'jotai';

const isAnalyticsReadyAtom = atom(false);
const segmentAnonymousIdAtom = atom(null);

const useAnalyticsReady = () => {
  const [isReady, setIsReady] = useAtom(isAnalyticsReadyAtom);
  const [segmentAnonymousId, setSegmentAnonymousId] = useAtom(
    segmentAnonymousIdAtom,
  );

  window.analytics.ready(() => {
    setIsReady(true);
    if (window?.analytics?.user?.().anonymousId() && !segmentAnonymousId) {
      setSegmentAnonymousId(window?.analytics?.user?.().anonymousId());
    }
  });

  return {isReady, segmentAnonymousId};
};

export default useAnalyticsReady;
