import React from 'react';
import PropTypes from 'prop-types';
import {DEFAULT_IMAGE_ID_RL, MEDIA_API_URL} from '../../../../constants';
import style from './style.scss';

const getLogoUrl = (src, operation = '', width = 0, height = 0) =>
  `${MEDIA_API_URL}/fetch/${encodeURIComponent(
    src,
  )}/transform~${operation}_${width}.${height}?fallback=${DEFAULT_IMAGE_ID_RL}`;

const RetailerLogo = ({fullLogoImageUrl, retailerName}) => {
  const url = getLogoUrl(fullLogoImageUrl);
  return (
    <div
      className={style['retailer-logo-container']}
      data-testid="retailer-logo">
      <img
        className={style['retailer-logo']}
        src={url}
        alt={`${retailerName} logo`}
      />
    </div>
  );
};

RetailerLogo.propTypes = {
  fullLogoImageUrl: PropTypes.string.isRequired,
  retailerName: PropTypes.string.isRequired,
};

export default RetailerLogo;
