import React, {createContext} from 'react';
import PropTypes from 'prop-types';
import Modal, {ModalHeader} from '@xo-union/tk-component-modals';
import useModal from '../../../hooks/useModal';

export const ModalContext = createContext();
const {Provider} = ModalContext;

export const ModalProvider = ({children}) => {
  const {
    visible,
    toggleModal,
    content,
    props,
    modalHeader,
    setModalHeader,
    modalSize,
    setModalSize,
    transactionalStep,
    setTransactionalStep,
    universalStep,
    setUniversalStep,
    setProps,
    onClose,
  } = useModal();

  return (
    <Provider
      value={{
        visible,
        toggleModal,
        content,
        props,
        modalHeader,
        setModalHeader,
        modalSize,
        setModalSize,
        transactionalStep,
        setTransactionalStep,
        universalStep,
        setUniversalStep,
        setProps,
        onClose,
      }}>
      {visible && (
        <Modal
          {...props}
          onClose={() => {
            toggleModal({isVisible: false});
            // eslint-disable-next-line react/prop-types
            if (props?.onClose) props.onClose();
          }}>
          {modalHeader && <ModalHeader>{modalHeader}</ModalHeader>}
          {content}
        </Modal>
      )}
      {children}
    </Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node,
};

ModalProvider.defaultProps = {
  children: null,
};

export default {Consumer: ModalContext.Consumer, Provider: ModalProvider};
