import React, {useContext} from 'react';
import {Helmet} from 'react-helmet';
import {useCoupleSummary} from '../../../../queries/couple';
import {AppContext} from '../../contexts/AppContext';
import {formatDate} from '../../../../utils/analytics';

const SEOScript = () => {
  const {initialProducts: products, memberId} = useContext(AppContext);
  const {data: {eventDate, registries} = {}} = useCoupleSummary(memberId);

  const generateSEOScript = () => {
    try {
      return `
        dataLayer.push({
          weddingDate: "${formatDate(eventDate)}",
          registryCount: ${registries.length},
          itemCount: ${products.length}
        });
      `;
    } catch (err) {
      console.error(`Failed to generate SEO Script`); // eslint-disable-line no-console
      return '';
    }
  };

  return (
    <Helmet>
      <script data-testid="helmet">
        {`
            window.dataLayer = window.dataLayer || [];
            ${generateSEOScript()}
        `}
      </script>
    </Helmet>
  );
};

export default SEOScript;
