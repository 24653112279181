/* eslint-disable import/prefer-default-export */
import flow from 'lodash.flow';

export const transform = (obj, transformations) => {
  return Object.keys(obj).reduce((m, key) => {
    let value = m[key];
    if (typeof m[key] === 'object' && m[key] !== null) {
      value = transform(m[key], transformations);
    } else {
      const transformValue = flow(
        Object.keys(transformations)
          .filter((t) =>
            t.includes('*')
              ? key.toLowerCase().includes(t.replace('*', ''))
              : t === key,
          )
          .map((t) => transformations[t]),
      );
      value = m[key] ? transformValue(m[key]) : m[key];
    }
    if (Array.isArray(m)) {
      return [...m.slice(0, key), value, ...m.slice(key + 1)];
    }
    return {
      ...m,
      [key]: value,
    };
  }, obj);
};
