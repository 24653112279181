import {BASE_URL} from '../../constants';
import {isTKRSGiftCard} from '../../utils/registryItem';

const getImageUrl = (product) => {
  let imageUrl;

  if (isTKRSGiftCard(product)) {
    imageUrl = product.galleryUrls?.[0];
  } else {
    imageUrl = product.imageUrl || product.imageUrls?.[0];
  }

  return imageUrl || '';
};

const getPrice = (product, selectedVariant) => {
  if (isTKRSGiftCard(product)) {
    return undefined;
  }

  if (selectedVariant) {
    return parseInt(selectedVariant.price, 10);
  }

  return Number((product.priceCents / 100).toFixed(2));
};

const mapProduct = (
  product,
  quantity,
  selectedVariant = null,
  giftCardAmount = null,
) => ({
  brand: product.brandName || product.brand,
  category:
    product.categoryName ||
    (product.mostSpecificCategoryBreadcrumbs &&
      product.mostSpecificCategoryBreadcrumbs.length > 0 &&
      product.mostSpecificCategoryBreadcrumbs
        .map(({name: categoryName}) => categoryName)
        .join('/')) ||
    null,
  image_url: product.image || getImageUrl(product),
  name: product.name,
  position: product.position,
  price: product.price || giftCardAmount || getPrice(product, selectedVariant),
  product_id: product.productId?.toString() || product.id?.toString() || '',
  quantity: quantity || product.quantity || product.numRequested,
  sku: product.sku,
  url: product.detailPageUrlKey
    ? `${BASE_URL}/registry/store/products/${product.detailPageUrlKey.replace(
        '.html',
        '',
      )}`
    : product.productUrl || product.url,
  variant: selectedVariant ? selectedVariant.sku : product.sku,
});

export default mapProduct;
