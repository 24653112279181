/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */
/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv'
/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler = (message, violations) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)
	console.warn(msg)
}
let onViolation = defaultValidationErrorHandler
let analytics = () => {
	return window.analytics
}
/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
	onViolation = options.onViolation || onViolation
}
/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message, schema) {
	const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true })
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'))
	if (!ajv.validate(schema, message) && ajv.errors) {
		onViolation(message, ajv.errors)
	}
}
/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message = {}) {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'javascript',
				version: '7.4.1',
			},
		},
	}
}
/**
 * @typedef ProductsItem
 * @property {string} [brand] - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product in the list
 * @property {string} [name] - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [url] - URL of the product page for product in the list
 * @property {string} [variant] - Product Variant displayed in the list
 */
/**
 * @typedef CartViewed
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {ProductsItem[]} products - Products displayed in the product list
 */
/**
 * @typedef ProductsItem1
 * @property {string} [brand] - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product in the list
 * @property {string} [name] - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [url] - URL of the product page for product in the list
 * @property {string} [variant] - Product Variant displayed in the list
 */
/**
 * @typedef CheckoutStarted
 * @property {string} [affiliation] - Store or affiliation from which this transaction occurred
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {ProductsItem1[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the transaction
 * @property {number} [tax] - Total tax associated with the transaction
 * @property {number} [total] - Revenue with discounts and coupons added
 */
/**
 * @typedef CheckoutStepCompleted
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [payment_method] - String representing the payment method chosen
 * @property {string} [shipping_method] - String representing the shipping method chosen
 * @property {number} [step] - Number representing a step in the checkout process
 */
/**
 * @typedef CheckoutStepViewed
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [payment_method] - String representing the payment method chosen
 * @property {string} [shipping_method] - String representing the shipping method chosen
 * @property {number} [step] - Number representing a step in the checkout process
 */
/**
 * @typedef ProductsItem2
 * @property {string} [brand] - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product in the list
 * @property {string} [name] - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [url] - URL of the product page for product in the list
 * @property {string} [variant] - Product Variant displayed in the list
 */
/**
 * @typedef OrderCompleted
 * @property {string} [affiliation] - Store or affiliation from which this transaction occurred
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} order_id - Order/transaction ID
 * @property {ProductsItem2[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the transaction
 * @property {number} [tax] - Total tax associated with the transaction
 * @property {number} [total] - Revenue with discounts and coupons added
 */
/**
 * @typedef PaymentInfoEntered
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} order_id - Order/transaction ID
 * @property {string} [payment_method] - String representing the payment method chosen
 * @property {string} [shipping_method] - String representing the shipping method chosen
 * @property {number} [step] - Number representing a step in the checkout process
 */
/**
 * @typedef ProductAdded
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string} [list_name] - The list or collection to which the product belongs in the interface (e.g. "search results", "carousel recommendations")
 * @property {string} [location] - Location where the event took place e.g. modal
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef ProductAddedToWishlist
 * @property {string} [brand] - Brand of the product
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string | null} [list_name] - List of products from where item was added
 * @property {string} [location] - Location where the product was clicked
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 * @property {string} wishlist_id - Wishlist ID to which the product was added
 * @property {string} [wishlist_name] - Wishlist name to which the product was added
 */
/**
 * @typedef ProductClicked
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string} [list_name] - The list and collection to which the product belongs in the interface (e.g. "search results", "carousel recommendations")
 * @property {string} [location] - Location where the event took place e.g. modal
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef FiltersItem
 * @property {string} [type] - ID of the filter type being used
 * @property {string} [value] - ID of the selection chosen
 */
/**
 * @typedef ProductsItem3
 * @property {string} [brand] - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product in the list
 * @property {string} [name] - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [url] - URL of the product page for product in the list
 * @property {string} [variant] - Product Variant displayed in the list
 */
/**
 * @typedef SortsItem
 * @property {string} [type] - ID of the sort type being used
 * @property {string} [value] - ID of the selection type being used
 */
/**
 * @typedef ProductListFiltered
 * @property {string} [category] - Product category being viewed
 * @property {FiltersItem[]} [filters] - Product filters that the customer is using
 * @property {string} [list_name] - Product list being viewed
 * @property {ProductsItem3[]} products - Products displayed in the product list
 * @property {SortsItem[]} [sorts] - Product sorting the customer is using
 */
/**
 * @typedef ProductsItem4
 * @property {string} [brand] - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product in the list
 * @property {string} [name] - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [url] - URL of the product page for product in the list
 * @property {string} [variant] - Product Variant displayed in the list
 */
/**
 * @typedef ProductListViewed
 * @property {string} [category] - Product category being viewed
 * @property {string} [list_name] - Product list being viewed
 * @property {string} [location] - Location where the event took place e.g. modal
 * @property {ProductsItem4[]} products - Products displayed in the product list
 */
/**
 * @typedef ProductRemoved
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef ProductRemovedFromWishlist
 * @property {string} [brand] - Brand of the product
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string} [list_name] - Product list from which product was removed
 * @property {string} [location] - Location where the product was clicked
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 * @property {string} wishlist_id - Wishlist ID to which the product was added
 * @property {string} [wishlist_name] - Wishlist name to which the product was added
 */
/**
 * @typedef ProductViewed
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef ProductsSearched
 * @property {string} [query] - Query the user searched with
 */
/**
 * @typedef WishlistCreated
 * @property {string} wishlist_id - Wishlist ID to which the product was added
 * @property {string} [wishlist_name] - Wishlist name to which the product was added
 */
/**
 * @typedef WishlistProductAddedToCart
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 * @property {string} wishlist_id - Wishlist ID to which the product was added
 * @property {string} [wishlist_name] - Wishlist name to which the product was added
 */
/**
 * @typedef WishlistProductRemovedFromCart
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - Cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 * @property {string} wishlist_id - Wishlist ID to which the product was added
 * @property {string} [wishlist_name] - Wishlist name to which the product was added
 */
/**
 * User viewed their shopping cart
 *
 * @param {CartViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cartViewed(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User viewed their shopping cart',
		properties: {
			context: {},
			properties: {
				properties: {
					cart_id: {
						description: 'Cart ID associated with the product displayed',
						type: ['string'],
					},
					products: {
						description: 'Products displayed in the product list',
						items: {
							properties: {
								brand: {
									description: 'Product Brand displayed in the list',
									type: ['string'],
								},
								category: {
									description: 'Product Category displayed in the list',
									type: ['string'],
								},
								coupon: {
									description: 'Coupon code associated with the product',
									type: ['string'],
								},
								image_url: {
									description: 'Image url of the product in the list',
									type: ['string'],
								},
								name: {
									description: 'Product Name displayed in the list',
									type: ['string'],
								},
								position: {
									description: 'Position of product in the list',
									type: ['number'],
								},
								price: {
									description: 'Product Price displayed in the list',
									type: ['number'],
								},
								product_id: {
									description: 'Product ID displayed in the list',
									type: ['string'],
								},
								quantity: {
									description: 'Product quantity displayed in the list',
									type: ['integer'],
								},
								sku: {
									description: 'Product SKU displayed in the list',
									type: ['string'],
								},
								url: {
									description:
										'URL of the product page for product in the list',
									type: ['string'],
								},
								variant: {
									description: 'Product Variant displayed in the list',
									type: ['string'],
								},
							},
							required: ['product_id'],
							type: 'object',
						},
						type: ['array'],
					},
				},
				required: ['products'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Cart Viewed',
		type: 'object',
	}
	const message = {
		event: 'Cart Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Cart Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User initiated the order process (a transaction is created)
 *
 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStarted(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User initiated the order process (a transaction is created)',
		properties: {
			context: {},
			properties: {
				properties: {
					affiliation: {
						description:
							'Store or affiliation from which this transaction occurred ',
						type: ['string'],
					},
					cart_id: {
						description: 'Cart ID associated with the product displayed',
						type: ['string'],
					},
					coupon: {
						description: 'Coupon code associated with the product',
						type: ['string'],
					},
					currency: {
						description: 'Currency code associated with the transaction',
						type: ['string'],
					},
					discount: {
						description: 'Total discount associated with the transaction',
						type: ['number'],
					},
					products: {
						description: 'Products displayed in the product list',
						items: {
							properties: {
								brand: {
									description: 'Product Brand displayed in the list',
									type: ['string'],
								},
								category: {
									description: 'Product Category displayed in the list',
									type: ['string'],
								},
								coupon: {
									description: 'Coupon code associated with the product',
									type: ['string'],
								},
								image_url: {
									description: 'Image url of the product in the list',
									type: ['string'],
								},
								name: {
									description: 'Product Name displayed in the list',
									type: ['string'],
								},
								position: {
									description: 'Position of product in the list',
									type: ['number'],
								},
								price: {
									description: 'Product Price displayed in the list',
									type: ['number'],
								},
								product_id: {
									description: 'Product ID displayed in the list',
									type: ['string'],
								},
								quantity: {
									description: 'Product quantity displayed in the list',
									type: ['integer'],
								},
								sku: {
									description: 'Product SKU displayed in the list',
									type: ['string'],
								},
								url: {
									description:
										'URL of the product page for product in the list',
									type: ['string'],
								},
								variant: {
									description: 'Product Variant displayed in the list',
									type: ['string'],
								},
							},
							required: ['product_id'],
							type: 'object',
						},
						type: ['array'],
					},
					revenue: {
						description: 'Revenue ($) associated with the transaction',
						type: ['number'],
					},
					shipping: {
						description: 'Shipping cost associated with the transaction',
						type: ['number'],
					},
					tax: {
						description: 'Total tax associated with the transaction',
						type: ['number'],
					},
					total: {
						description: 'Revenue with discounts and coupons added',
						type: ['number'],
					},
				},
				required: ['revenue', 'products'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Checkout Started',
		type: 'object',
	}
	const message = {
		event: 'Checkout Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed a checkout step
 *
 * @param {CheckoutStepCompleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepCompleted(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User completed a checkout step',
		properties: {
			context: {},
			properties: {
				properties: {
					cart_id: {
						description: 'Cart ID associated with the product displayed',
						type: ['string'],
					},
					payment_method: {
						description: 'String representing the payment method chosen',
						type: ['string'],
					},
					shipping_method: {
						description: 'String representing the shipping method chosen',
						type: ['string'],
					},
					step: {
						description: 'Number representing a step in the checkout process',
						type: ['integer'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Checkout Step Completed',
		type: 'object',
	}
	const message = {
		event: 'Checkout Step Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed a checkout step
 *
 * @param {CheckoutStepViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepViewed(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User viewed a checkout step',
		properties: {
			context: {},
			properties: {
				properties: {
					cart_id: {
						description: 'Cart ID associated with the product displayed',
						type: ['string'],
					},
					payment_method: {
						description: 'String representing the payment method chosen',
						type: ['string'],
					},
					shipping_method: {
						description: 'String representing the shipping method chosen',
						type: ['string'],
					},
					step: {
						description: 'Number representing a step in the checkout process',
						type: ['integer'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		title: 'Checkout Step Viewed',
		type: 'object',
	}
	const message = {
		event: 'Checkout Step Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed the order
 *
 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCompleted(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User completed the order',
		properties: {
			context: {},
			properties: {
				properties: {
					affiliation: {
						description:
							'Store or affiliation from which this transaction occurred ',
						type: ['string'],
					},
					coupon: {
						description: 'Coupon code associated with the product',
						type: ['string'],
					},
					currency: {
						description: 'Currency code associated with the transaction',
						type: ['string'],
					},
					discount: {
						description: 'Total discount associated with the transaction',
						type: ['number'],
					},
					order_id: {
						description: 'Order/transaction ID',
						type: ['string'],
					},
					products: {
						description: 'Products displayed in the product list',
						items: {
							properties: {
								brand: {
									description: 'Product Brand displayed in the list',
									type: ['string'],
								},
								category: {
									description: 'Product Category displayed in the list',
									type: ['string'],
								},
								coupon: {
									description: 'Coupon code associated with the product',
									type: ['string'],
								},
								image_url: {
									description: 'Image url of the product in the list',
									type: ['string'],
								},
								name: {
									description: 'Product Name displayed in the list',
									type: ['string'],
								},
								position: {
									description: 'Position of product in the list',
									type: ['number'],
								},
								price: {
									description: 'Product Price displayed in the list',
									type: ['number'],
								},
								product_id: {
									description: 'Product ID displayed in the list',
									type: ['string'],
								},
								quantity: {
									description: 'Product quantity displayed in the list',
									type: ['integer'],
								},
								sku: {
									description: 'Product SKU displayed in the list',
									type: ['string'],
								},
								url: {
									description:
										'URL of the product page for product in the list',
									type: ['string'],
								},
								variant: {
									description: 'Product Variant displayed in the list',
									type: ['string'],
								},
							},
							required: ['product_id'],
							type: 'object',
						},
						type: ['array'],
					},
					revenue: {
						description: 'Revenue ($) associated with the transaction',
						type: ['number'],
					},
					shipping: {
						description: 'Shipping cost associated with the transaction',
						type: ['number'],
					},
					tax: {
						description: 'Total tax associated with the transaction',
						type: ['number'],
					},
					total: {
						description: 'Revenue with discounts and coupons added',
						type: ['number'],
					},
				},
				required: ['products', 'order_id', 'revenue'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Order Completed',
		type: 'object',
	}
	const message = {
		event: 'Order Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Order Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Page Viewed' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		properties: {
			context: {},
			properties: {
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added payment information
 *
 * @param {PaymentInfoEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentInfoEntered(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User added payment information',
		properties: {
			context: {},
			properties: {
				properties: {
					cart_id: {
						description: 'Cart ID associated with the product displayed',
						type: ['string'],
					},
					order_id: {
						description: 'Order/transaction ID',
						type: ['string'],
					},
					payment_method: {
						description: 'String representing the payment method chosen',
						type: ['string'],
					},
					shipping_method: {
						description: 'String representing the shipping method chosen',
						type: ['string'],
					},
					step: {
						description: 'Number representing a step in the checkout process',
						type: ['integer'],
					},
				},
				required: ['order_id'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Payment Info Entered',
		type: 'object',
	}
	const message = {
		event: 'Payment Info Entered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Payment Info Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added a product to their shopping cart
 *
 * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAdded(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User added a product to their shopping cart',
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand of the product',
						type: ['string'],
					},
					cart_id: {
						description: 'Cart ID associated with the product displayed',
						type: ['string'],
					},
					category: {
						description: 'Product category being viewed',
						type: ['string'],
					},
					coupon: {
						description: 'Coupon code associated with the product',
						type: ['string'],
					},
					image_url: {
						description: 'Image url of the product',
						type: ['string'],
					},
					list_name: {
						description:
							'The list or collection to which the product belongs in the interface (e.g. "search results", "carousel recommendations")',
						type: 'string',
					},
					location: {
						description: 'Location where the event took place e.g. modal',
						type: 'string',
					},
					name: {
						description: 'Name of the product',
						type: ['string'],
					},
					position: {
						description: 'Position of product in the list',
						type: ['integer'],
					},
					price: {
						description: 'Price of the product',
						type: ['number'],
					},
					product_id: {
						description: 'Database ID of the product',
						type: ['string'],
					},
					quantity: {
						description: 'Quantity of the product',
						type: ['integer'],
					},
					sku: {
						description: 'Sku of the product',
						type: ['string'],
					},
					url: {
						description: 'URL of the product page for product',
						type: ['string'],
					},
					variant: {
						description: 'Variant of the product',
						type: ['string'],
					},
				},
				required: ['price', 'product_id'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Product Added',
		type: 'object',
	}
	const message = {
		event: 'Product Added',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Product Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added a product to the wish list
 *
 * @param {ProductAddedToWishlist} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAddedToWishlist(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User added a product to the wish list',
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand of the product',
						type: ['string'],
					},
					category: {
						description: 'Product category being viewed',
						type: ['string'],
					},
					coupon: {
						description: 'Coupon code associated with the product',
						type: ['string'],
					},
					image_url: {
						description: 'Image url of the product',
						type: ['string'],
					},
					list_name: {
						description: 'List of products from where item was added',
						type: ['string', 'null'],
					},
					location: {
						description: 'Location where the product was clicked',
						type: 'string',
					},
					name: {
						description: 'Name of the product',
						type: ['string'],
					},
					position: {
						description: 'Position of product in the list',
						type: ['integer'],
					},
					price: {
						description: 'Price of the product',
						type: ['number'],
					},
					product_id: {
						description: 'Database ID of the product',
						type: ['string'],
					},
					quantity: {
						description: 'Quantity of the product',
						type: ['integer'],
					},
					sku: {
						description: 'Sku of the product',
						type: ['string'],
					},
					url: {
						description: 'URL of the product page for product',
						type: ['string'],
					},
					variant: {
						description: 'Variant of the product',
						type: ['string'],
					},
					wishlist_id: {
						description: 'Wishlist ID to which the product was added',
						type: ['string'],
					},
					wishlist_name: {
						description: 'Wishlist name to which the product was added',
						type: ['string'],
					},
				},
				required: ['wishlist_id', 'product_id', 'price'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Product Added to Wishlist',
		type: 'object',
	}
	const message = {
		event: 'Product Added to Wishlist',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Product Added to Wishlist',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked on a product
 *
 * @param {ProductClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productClicked(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User clicked on a product',
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand of the product',
						type: ['string'],
					},
					cart_id: {
						description: 'Cart ID associated with the product displayed',
						type: ['string'],
					},
					category: {
						description: 'Product category being viewed',
						type: ['string'],
					},
					coupon: {
						description: 'Coupon code associated with the product',
						type: ['string'],
					},
					image_url: {
						description: 'Image url of the product',
						type: ['string'],
					},
					list_name: {
						description:
							'The list and collection to which the product belongs in the interface (e.g. "search results", "carousel recommendations")',
						type: 'string',
					},
					location: {
						description: 'Location where the event took place e.g. modal',
						type: 'string',
					},
					name: {
						description: 'Name of the product',
						type: ['string'],
					},
					position: {
						description: 'Position of product in the list',
						type: ['integer'],
					},
					price: {
						description: 'Price of the product',
						type: ['number'],
					},
					product_id: {
						description: 'Database ID of the product',
						type: ['string'],
					},
					quantity: {
						description: 'Quantity of the product',
						type: ['integer'],
					},
					sku: {
						description: 'Sku of the product',
						type: ['string'],
					},
					url: {
						description: 'URL of the product page for product',
						type: ['string'],
					},
					variant: {
						description: 'Variant of the product',
						type: ['string'],
					},
				},
				required: ['product_id', 'price'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Product Clicked',
		type: 'object',
	}
	const message = {
		event: 'Product Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Product Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User filtered a product list or category
 *
 * @param {ProductListFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListFiltered(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User filtered a product list or category',
		properties: {
			context: {},
			properties: {
				properties: {
					category: {
						description: 'Product category being viewed',
						type: ['string'],
					},
					filters: {
						description: 'Product filters that the customer is using',
						items: {
							properties: {
								type: {
									description: 'ID of the filter type being used',
									type: ['string'],
								},
								value: {
									description: 'ID of the selection chosen',
									type: ['string'],
								},
							},
							type: 'object',
						},
						type: ['array'],
					},
					list_name: {
						description: 'Product list being viewed',
						type: ['string'],
					},
					products: {
						description: 'Products displayed in the product list',
						items: {
							properties: {
								brand: {
									description: 'Product Brand displayed in the list',
									type: ['string'],
								},
								category: {
									description: 'Product Category displayed in the list',
									type: ['string'],
								},
								coupon: {
									description: 'Coupon code associated with the product',
									type: ['string'],
								},
								image_url: {
									description: 'Image url of the product in the list',
									type: ['string'],
								},
								name: {
									description: 'Product Name displayed in the list',
									type: ['string'],
								},
								position: {
									description: 'Position of product in the list',
									type: ['number'],
								},
								price: {
									description: 'Product Price displayed in the list',
									type: ['number'],
								},
								product_id: {
									description: 'Product ID displayed in the list',
									type: ['string'],
								},
								quantity: {
									description: 'Product quantity displayed in the list',
									type: ['integer'],
								},
								sku: {
									description: 'Product SKU displayed in the list',
									type: ['string'],
								},
								url: {
									description:
										'URL of the product page for product in the list',
									type: ['string'],
								},
								variant: {
									description: 'Product Variant displayed in the list',
									type: ['string'],
								},
							},
							required: ['product_id'],
							type: 'object',
						},
						type: ['array'],
					},
					sorts: {
						description: 'Product sorting the customer is using',
						items: {
							properties: {
								type: {
									description: 'ID of the sort type being used',
									type: ['string'],
								},
								value: {
									description: 'ID of the selection type being used',
									type: ['string'],
								},
							},
							type: 'object',
						},
						type: ['array'],
					},
				},
				required: ['products'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Product List Filtered',
		type: 'object',
	}
	const message = {
		event: 'Product List Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Product List Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed a product list or category
 *
 * @param {ProductListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListViewed(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User viewed a product list or category',
		properties: {
			context: {},
			properties: {
				properties: {
					category: {
						description: 'Product category being viewed',
						type: ['string'],
					},
					list_name: {
						description: 'Product list being viewed',
						type: ['string'],
					},
					location: {
						description: 'Location where the event took place e.g. modal',
						type: 'string',
					},
					products: {
						description: 'Products displayed in the product list',
						items: {
							properties: {
								brand: {
									description: 'Product Brand displayed in the list',
									type: ['string'],
								},
								category: {
									description: 'Product Category displayed in the list',
									type: ['string'],
								},
								coupon: {
									description: 'Coupon code associated with the product',
									type: ['string'],
								},
								image_url: {
									description: 'Image url of the product in the list',
									type: ['string'],
								},
								name: {
									description: 'Product Name displayed in the list',
									type: ['string'],
								},
								position: {
									description: 'Position of product in the list',
									type: ['number'],
								},
								price: {
									description: 'Product Price displayed in the list',
									type: ['number'],
								},
								product_id: {
									description: 'Product ID displayed in the list',
									type: ['string'],
								},
								quantity: {
									description: 'Product quantity displayed in the list',
									type: ['integer'],
								},
								sku: {
									description: 'Product SKU displayed in the list',
									type: ['string'],
								},
								url: {
									description:
										'URL of the product page for product in the list',
									type: ['string'],
								},
								variant: {
									description: 'Product Variant displayed in the list',
									type: ['string'],
								},
							},
							required: ['product_id'],
							type: 'object',
						},
						type: ['array'],
					},
				},
				required: ['products'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Product List Viewed',
		type: 'object',
	}
	const message = {
		event: 'Product List Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Product List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed a product from their shopping cart
 *
 * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productRemoved(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User removed a product from their shopping cart',
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand of the product',
						type: ['string'],
					},
					cart_id: {
						description: 'Cart ID associated with the product displayed',
						type: ['string'],
					},
					category: {
						description: 'Product category being viewed',
						type: ['string'],
					},
					coupon: {
						description: 'Coupon code associated with the product',
						type: ['string'],
					},
					image_url: {
						description: 'Image url of the product',
						type: ['string'],
					},
					name: {
						description: 'Name of the product',
						type: ['string'],
					},
					position: {
						description: 'Position of product in the list',
						type: ['integer'],
					},
					price: {
						description: 'Price of the product',
						type: ['number'],
					},
					product_id: {
						description: 'Database ID of the product',
						type: ['string'],
					},
					quantity: {
						description: 'Quantity of the product',
						type: ['integer'],
					},
					sku: {
						description: 'Sku of the product',
						type: ['string'],
					},
					url: {
						description: 'URL of the product page for product',
						type: ['string'],
					},
					variant: {
						description: 'Variant of the product',
						type: ['string'],
					},
				},
				required: ['price', 'product_id'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Product Removed',
		type: 'object',
	}
	const message = {
		event: 'Product Removed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Product Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed a product from the wish list
 *
 * @param {ProductRemovedFromWishlist} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productRemovedFromWishlist(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User removed a product from the wish list',
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand of the product',
						type: ['string'],
					},
					category: {
						description: 'Product category being viewed',
						type: ['string'],
					},
					coupon: {
						description: 'Coupon code associated with the product',
						type: ['string'],
					},
					image_url: {
						description: 'Image url of the product',
						type: ['string'],
					},
					list_name: {
						description: 'Product list from which product was removed',
						type: 'string',
					},
					location: {
						description: 'Location where the product was clicked',
						type: 'string',
					},
					name: {
						description: 'Name of the product',
						type: ['string'],
					},
					position: {
						description: 'Position of product in the list',
						type: ['integer'],
					},
					price: {
						description: 'Price of the product',
						type: ['number'],
					},
					product_id: {
						description: 'Database ID of the product',
						type: ['string'],
					},
					quantity: {
						description: 'Quantity of the product',
						type: ['integer'],
					},
					sku: {
						description: 'Sku of the product',
						type: ['string'],
					},
					url: {
						description: 'URL of the product page for product',
						type: ['string'],
					},
					variant: {
						description: 'Variant of the product',
						type: ['string'],
					},
					wishlist_id: {
						description: 'Wishlist ID to which the product was added',
						type: ['string'],
					},
					wishlist_name: {
						description: 'Wishlist name to which the product was added',
						type: ['string'],
					},
				},
				required: ['wishlist_id', 'product_id', 'price'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Product Removed from Wishlist',
		type: 'object',
	}
	const message = {
		event: 'Product Removed from Wishlist',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Product Removed from Wishlist',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed a products details
 *
 * @param {ProductViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productViewed(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User viewed a products details',
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand of the product',
						type: ['string'],
					},
					cart_id: {
						description: 'Cart ID associated with the product displayed',
						type: ['string'],
					},
					category: {
						description: 'Product category being viewed',
						type: ['string'],
					},
					coupon: {
						description: 'Coupon code associated with the product',
						type: ['string'],
					},
					image_url: {
						description: 'Image url of the product',
						type: ['string'],
					},
					name: {
						description: 'Name of the product',
						type: ['string'],
					},
					position: {
						description: 'Position of product in the list',
						type: ['integer'],
					},
					price: {
						description: 'Price of the product',
						type: ['number'],
					},
					product_id: {
						description: 'Database ID of the product',
						type: ['string'],
					},
					quantity: {
						description: 'Quantity of the product',
						type: ['integer'],
					},
					sku: {
						description: 'Sku of the product',
						type: ['string'],
					},
					url: {
						description: 'URL of the product page for product',
						type: ['string'],
					},
					variant: {
						description: 'Variant of the product',
						type: ['string'],
					},
				},
				required: ['price', 'product_id'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Product Viewed',
		type: 'object',
	}
	const message = {
		event: 'Product Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Product Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User searched for products
 *
 * @param {ProductsSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsSearched(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User searched for products',
		properties: {
			context: {},
			properties: {
				properties: {
					query: {
						description: 'Query the user searched with',
						type: ['string'],
					},
				},
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Products Searched',
		type: 'object',
	}
	const message = {
		event: 'Products Searched',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Products Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User created a wishlist
 *
 * @param {WishlistCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function wishlistCreated(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User created a wishlist',
		properties: {
			context: {},
			properties: {
				properties: {
					wishlist_id: {
						description: 'Wishlist ID to which the product was added',
						type: ['string'],
					},
					wishlist_name: {
						description: 'Wishlist name to which the product was added',
						type: ['string'],
					},
				},
				required: ['wishlist_id'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Wishlist Created',
		type: 'object',
	}
	const message = {
		event: 'Wishlist Created',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Wishlist Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added a wishlist product to the cart
 *
 * @param {WishlistProductAddedToCart} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function wishlistProductAddedToCart(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User added a wishlist product to the cart',
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand of the product',
						type: ['string'],
					},
					cart_id: {
						description: 'Cart ID associated with the product displayed',
						type: ['string'],
					},
					category: {
						description: 'Product category being viewed',
						type: ['string'],
					},
					coupon: {
						description: 'Coupon code associated with the product',
						type: ['string'],
					},
					image_url: {
						description: 'Image url of the product',
						type: ['string'],
					},
					name: {
						description: 'Name of the product',
						type: ['string'],
					},
					position: {
						description: 'Position of product in the list',
						type: ['integer'],
					},
					price: {
						description: 'Price of the product',
						type: ['number'],
					},
					product_id: {
						description: 'Database ID of the product',
						type: ['string'],
					},
					quantity: {
						description: 'Quantity of the product',
						type: ['integer'],
					},
					sku: {
						description: 'Sku of the product',
						type: ['string'],
					},
					url: {
						description: 'URL of the product page for product',
						type: ['string'],
					},
					variant: {
						description: 'Variant of the product',
						type: ['string'],
					},
					wishlist_id: {
						description: 'Wishlist ID to which the product was added',
						type: ['string'],
					},
					wishlist_name: {
						description: 'Wishlist name to which the product was added',
						type: ['string'],
					},
				},
				required: ['wishlist_id', 'product_id', 'price'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Wishlist Product Added to Cart',
		type: 'object',
	}
	const message = {
		event: 'Wishlist Product Added to Cart',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Wishlist Product Added to Cart',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added a wishlist product to the cart
 *
 * @param {WishlistProductRemovedFromCart} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function wishlistProductRemovedFromCart(props, options, callback) {
	const schema = {
		$schema: 'http://json-schema.org/draft-04/schema#',
		description: 'User added a wishlist product to the cart',
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand of the product',
						type: ['string'],
					},
					cart_id: {
						description: 'Cart ID associated with the product displayed',
						type: ['string'],
					},
					category: {
						description: 'Product category being viewed',
						type: ['string'],
					},
					coupon: {
						description: 'Coupon code associated with the product',
						type: ['string'],
					},
					image_url: {
						description: 'Image url of the product',
						type: ['string'],
					},
					name: {
						description: 'Name of the product',
						type: ['string'],
					},
					position: {
						description: 'Position of product in the list',
						type: ['integer'],
					},
					price: {
						description: 'Price of the product',
						type: ['number'],
					},
					product_id: {
						description: 'Database ID of the product',
						type: ['string'],
					},
					quantity: {
						description: 'Quantity of the product',
						type: ['integer'],
					},
					sku: {
						description: 'Sku of the product',
						type: ['string'],
					},
					url: {
						description: 'URL of the product page for product',
						type: ['string'],
					},
					variant: {
						description: 'Variant of the product',
						type: ['string'],
					},
					wishlist_id: {
						description: 'Wishlist ID to which the product was added',
						type: ['string'],
					},
					wishlist_name: {
						description: 'Wishlist name to which the product was added',
						type: ['string'],
					},
				},
				required: ['wishlist_id', 'product_id', 'price'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Wishlist Product Removed from Cart',
		type: 'object',
	}
	const message = {
		event: 'Wishlist Product Removed from Cart',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)
	const a = analytics()
	if (a) {
		a.track(
			'Wishlist Product Removed from Cart',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * User viewed their shopping cart
	 *
	 * @param {CartViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cartViewed,
	/**
	 * User initiated the order process (a transaction is created)
	 *
	 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStarted,
	/**
	 * User completed a checkout step
	 *
	 * @param {CheckoutStepCompleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepCompleted,
	/**
	 * User viewed a checkout step
	 *
	 * @param {CheckoutStepViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepViewed,
	/**
	 * User completed the order
	 *
	 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCompleted,
	/**
	 * Fires a 'Page Viewed' track call.
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * User added payment information
	 *
	 * @param {PaymentInfoEntered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentInfoEntered,
	/**
	 * User added a product to their shopping cart
	 *
	 * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productAdded,
	/**
	 * User added a product to the wish list
	 *
	 * @param {ProductAddedToWishlist} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productAddedToWishlist,
	/**
	 * User clicked on a product
	 *
	 * @param {ProductClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productClicked,
	/**
	 * User filtered a product list or category
	 *
	 * @param {ProductListFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productListFiltered,
	/**
	 * User viewed a product list or category
	 *
	 * @param {ProductListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productListViewed,
	/**
	 * User removed a product from their shopping cart
	 *
	 * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productRemoved,
	/**
	 * User removed a product from the wish list
	 *
	 * @param {ProductRemovedFromWishlist} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productRemovedFromWishlist,
	/**
	 * User viewed a products details
	 *
	 * @param {ProductViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productViewed,
	/**
	 * User searched for products
	 *
	 * @param {ProductsSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsSearched,
	/**
	 * User created a wishlist
	 *
	 * @param {WishlistCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	wishlistCreated,
	/**
	 * User added a wishlist product to the cart
	 *
	 * @param {WishlistProductAddedToCart} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	wishlistProductAddedToCart,
	/**
	 * User added a wishlist product to the cart
	 *
	 * @param {WishlistProductRemovedFromCart} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	wishlistProductRemovedFromCart,
}
export default new Proxy(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method]
		}
		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/xogrp/protocols/tracking-plans/rs_1lU4WeYeaI0wYPSPd3GEX3zQROh`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
