import React, {useContext, useState} from 'react';
import {PillBadge} from '@xo-union/tk-component-badges';
import {Row} from '@xo-union/tk-component-grid';
import {Button} from '@xo-union/tk-component-buttons';
import {compose} from '@xo-union/react-css-modules';
import {H2} from '@xo-union/tk-ui-typography';
import {MultiSelectFilter, SingleSelectFilter} from '../Filters';
import {ProductsContext} from '../../contexts/ProductsContext';
import {SortFiltersContext} from '../../contexts/SortFiltersContext';
import {ModalContext} from '../../contexts/ModalContext';
import makeTestId from '../../utils/makeTestId';
import style from './style.scss';

const SortFilterModal = () => {
  const {onClear} = useContext(ProductsContext);
  const {currentFilterCount, filterOptions} = useContext(SortFiltersContext);
  const {toggleModal, setModalHeader} = useContext(ModalContext);
  const onClose = () =>
    toggleModal({
      isVisible: false,
    });
  const [isInitialRender, setIsInitialRender] = useState(true);

  const classes = compose({
    medium: style['sort-filter-modal'],
  });

  const clickHandler = () => {
    setModalHeader(null);
    toggleModal({
      isVisible: true,
      props: {
        size: 'md',
        classes,
        'data-testid': 'product-grid-sort-filter-modal',
      },
      content: (
        <>
          <div className={style['sort-filter-modal-content']}>
            <H2 className={style.header}>Filter/Sort</H2>
            <span className={style['section-header']}>Price</span>
            <MultiSelectFilter
              testId="product-grid-sort-filter-modal"
              type="price"
            />
            {filterOptions.stores.length > 0 && (
              <>
                <hr />
                <span className={style['section-header']}>Store</span>
                <MultiSelectFilter
                  testId="product-grid-stores-filter-modal"
                  type="stores"
                />
              </>
            )}
            <hr />
            <span className={style['section-header']}>Status</span>
            <MultiSelectFilter
              testId="product-grid-sort-filter-modal"
              type="status"
            />
            <hr />
            <span className={style['section-header']}>Sort by</span>
            <SingleSelectFilter
              testId="product-grid-sort-filter-modal"
              type="sort"
            />
          </div>
          <div className={style['sort-filter-modal-actions']}>
            <Button
              color="secondary"
              data-testid={makeTestId(
                'product-grid',
                'sort-filter-modal-clear-btn',
              )}
              onClick={() => onClear()}
              size="md">
              Clear
            </Button>
            <Button
              data-testid={makeTestId(
                'product-grid',
                'sort-filter-modal-apply-btn',
              )}
              onClick={() => onClose()}
              size="md">
              Apply
            </Button>
          </div>
        </>
      ),
    });
  };
  return (
    <Row
      className={style['product-grid-sort-filter']}
      data-testid="product-grid-sort-filter"
      hGutters={false}>
      <Button
        className={style['sort-filter-modal-button']}
        color="secondary"
        data-testid="product-grid-sort-filter-modal-open-btn"
        onClick={() => {
          if (isInitialRender) setIsInitialRender(false);
          clickHandler();
        }}
        size="md">
        Filter/Sort
        {currentFilterCount > 0 && (
          <PillBadge className={style['pill-badge']}>
            {currentFilterCount}
          </PillBadge>
        )}
      </Button>
    </Row>
  );
};

export default SortFilterModal;
