import React, {useContext, useEffect, useMemo} from 'react';
import {compose} from '@xo-union/react-css-modules';
import {InlineAlert} from '@xo-union/tk-component-alerts';
import {Button} from '@xo-union/tk-component-buttons';
import {
  Column,
  Row,
  TopLevelContainer,
  ContainerWithStickyColumn,
} from '@xo-union/tk-component-grid';
import {Body1, H2, H4} from '@xo-union/tk-ui-typography';
import GridSpinner from '../GridSpinner';
import FilterPills from '../FilterPills';
import NonPartnerStores from '../../../NonPartnerStores';
import ProductCard from '../ProductCard';
import SideFilters from '../SideFilters';
import SkeletonGrid from '../SkeletonGrid';
import SortFilterModal from '../SortFilterModal';
import SortSelect from '../SortSelect';
import SEOScript from '../SEOScript';
import {AppContext} from '../../contexts/AppContext';
import {ErrorContext} from '../../contexts/ErrorContext';
import {ProductsContext} from '../../contexts/ProductsContext';
import {useCoupleSummary} from '../../../../queries/couple';
import {shouldShowRegistries} from '../../../../utils/registry';
import {CASH_FUND, MANUAL_REGISTRY_TYPE} from '../../../../constants';
import impressionTracking from '../../../../utils/impressionTracking';
import style from './style.scss';

const headerClasses = compose({
  'top-level-container': style['header-container'],
});

const containerClasses = compose({
  'top-level-container': style['top-level-container'],
  'sticky-left-column': style['sticky-left-column'],
});

const getManualRegistries = (registries) =>
  registries &&
  registries.filter((registry) => {
    const isPartnerRetailer = registry.retailer && registry.retailer.isPartner;
    const isManualRegistry = registry.registryType === MANUAL_REGISTRY_TYPE;

    return !isPartnerRetailer && isManualRegistry;
  });

const ProductList = () => {
  const {application, isMobile, memberId, coupleId} = useContext(AppContext);
  const {data: {hasTransactionalRegistry, registries} = {}} = useCoupleSummary(
    memberId,
    coupleId,
  );
  const {error} = useContext(ErrorContext);
  const {
    isLoading,
    isFetched,
    onClear,
    products,
    productsError,
    hasFetchedInitialProducts,
  } = useContext(ProductsContext);
  const manualRegistries = getManualRegistries(registries);
  const showNonPartnerStores =
    hasTransactionalRegistry &&
    shouldShowRegistries(manualRegistries, products) &&
    manualRegistries.length > 0;

  const indexOfFirstCashFund = useMemo(() => {
    return products?.findIndex((p, i) => {
      const prevProduct = (i > 0 && products[i - 1]) || null;
      return p.type === CASH_FUND && prevProduct?.type !== CASH_FUND;
    });
  }, [products]);

  useEffect(() => {
    // Remove event listeners for impression tracking on unmount
    return () => {
      impressionTracking.cancelImpressionTracking();
    };
  }, []);

  if (!hasFetchedInitialProducts) return <GridSpinner />;

  const renderMainContent = () =>
    products.length ? (
      <>
        {products.map((product, index) => {
          const isFirstCashFund = indexOfFirstCashFund === index;
          return (
            <Column
              xs="6"
              lg="4"
              xl="3"
              key={product._id || product.sku || product.cashFundId}
              className={style['product-card-wrapper']}>
              <ProductCard
                position={index + 1}
                product={product}
                isFirstCashFund={isFirstCashFund}
              />
            </Column>
          );
        })}

        {showNonPartnerStores && (
          <Column xs="12" className={style['non-partner-stores']}>
            <div className={style.divider} />

            <div>
              <H4>More Gifts</H4>
              <Body1 className={style.subtext}>
                We love these stores. Since they&apos;re not partners with The
                Knot, click out to see our gifts.
              </Body1>
            </div>
            <NonPartnerStores
              application={application}
              isMobile={isMobile}
              memberId={memberId}
              registries={manualRegistries}
              showSectionHeader
            />
          </Column>
        )}
      </>
    ) : (
      <Column
        xs="12"
        data-testid="no-results-row"
        className={style['no-results']}>
        <p>No Results Found</p>
        <p>Hey, it happens! Try adjusting your filters.</p>
        <Button size="md" onClick={() => onClear()}>
          Reset Filters
        </Button>
      </Column>
    );

  const mainContent = (
    <Row
      data-testid="product-grid-container"
      name="product-grid-container"
      vGutters={false}
      className={style.row}>
      <Column xs="12">
        <FilterPills />
      </Column>
      {isLoading || !isFetched ? <SkeletonGrid /> : renderMainContent()}
    </Row>
  );

  return (
    <>
      <SEOScript />

      <TopLevelContainer>
        <Row hGutters={false}>
          <Column xs="12">
            {(error !== '' || productsError) && (
              <InlineAlert className={style.error} type="error">
                {error || 'Something went wrong. Please try again.'}
              </InlineAlert>
            )}
          </Column>
        </Row>
      </TopLevelContainer>

      <TopLevelContainer
        classes={headerClasses}
        data-testid="toplevel-products-container">
        <Row hGutters={false}>
          <Column xs="12" className={style.wishlist}>
            <H2 id="title-our-wish-list">Our Wish List</H2>
            <SortFilterModal />
            <SortSelect />
          </Column>
        </Row>
      </TopLevelContainer>

      <ContainerWithStickyColumn
        data-testid="sticky-container"
        mainContent={mainContent}
        stickyColumnContent={<SideFilters />}
        classes={containerClasses}
      />
    </>
  );
};

export default ProductList;
