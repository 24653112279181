import React from 'react';
import CartIcon from '@tkww/reg-shared-ui-cart-icon';
import {
  FullBleedContainer,
  TopLevelContainer,
  Column,
  Row,
} from '@xo-union/tk-component-grid';
import BrandLogo from '@xo-union/component-brand-logo';
import {APPLICATION_GVR} from '../../../../constants';
import {queryClient} from '../../../../queries';
import style from './style.scss';

const Header = () => {
  return (
    <FullBleedContainer className={style.container}>
      <TopLevelContainer>
        <Row>
          <Column xs="12" className={style.content}>
            <a
              aria-label="The Knot Registry"
              href={`${process.env.REACT_APP_WEBUI_HOST}`}
              className={style.logo}>
              <BrandLogo
                name="theknot"
                color="primary"
                size="lg"
                className={style.logo}
              />
            </a>
            <CartIcon
              application={APPLICATION_GVR}
              onClick={null}
              showAlways={false}
              size="sm"
              queryClient={queryClient}
              withRedirect
            />
          </Column>
        </Row>
      </TopLevelContainer>
    </FullBleedContainer>
  );
};

export default Header;
