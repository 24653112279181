import React from 'react';
import {Skeleton, Bone} from '@xo-union/tk-ui-skeleton';
import {Column} from '@xo-union/tk-component-grid';
import style from './style.scss';

const SkeletonGrid = () =>
  Array.from({length: 20}, (v, i) => i).map((x) => (
    <Column
      xs="6"
      lg="4"
      xl="3"
      className={style['skeleton-grid']}
      data-testid={`skeleton-grid-col-${x}`}
      role="group"
      key={x}>
      <Skeleton
        aria-label="Loading products..."
        className={style['skeleton-card-container']}
        data-testid={`skeleton-card-${x}`}>
        <Bone className={style['bone-image']} />
        <Bone className={style['bone-store-name']} />
        <Bone className={style['bone-name']} />
        <Bone className={style['bone-price']} />
      </Skeleton>
    </Column>
  ));

export default SkeletonGrid;
