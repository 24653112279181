import React, {createContext, useState} from 'react';
import PropTypes from 'prop-types';
import {shallow} from 'zustand/shallow';
import {findIndex} from 'lodash-es';
import getFilterOptions, {
  getInitialFilterOptions,
} from '../utils/getFilterOptions';
import useGlobalProductStore from '../../../hooks/useGlobalProductStore';

export const SortFiltersContext = createContext({
  clearFilters: () => {},
  currentSortFilters: {},
  filterOptions: {},
  initialFilters: {},
  initialFilterOptions: {},
  selectedFilterOptions: {},
  selectFilter: () => {},
  setCurrentSortFilters: () => {},
  setSelectedFilterOptions: () => {},
});

export const SortFiltersContextProvider = ({children}) => {
  const stores = useGlobalProductStore((state) => state.stores, shallow);
  const filterOptions = getFilterOptions(stores);
  const initialFilterOptions = getInitialFilterOptions();
  const initialFilters = {
    filters: [],
    sort: [initialFilterOptions.sort.value],
  };
  const [currentSortFilters, setCurrentSortFilters] = useState(initialFilters);
  const [selectedFilterOptions, setSelectedFilterOptions] =
    useState(initialFilterOptions);

  const mapFilters = (appliedFilters) => {
    const sortFilters = {
      filters: {
        priceRange: appliedFilters.price.map((item) => item.value),
        stores: appliedFilters.stores.map((item) => item.value),
      },
      sort: [appliedFilters.sort.value],
    };

    if (appliedFilters.status.length === 1)
      sortFilters.filters.fulfilled = appliedFilters.status[0].value;

    return sortFilters;
  };

  const clearFilters = (filterTypes, cb) => {
    if (filterTypes.length) {
      const newFilters = selectedFilterOptions;
      for (let i = 0; i < filterTypes.length; i += 1) {
        const type = filterTypes[i];
        newFilters[type] = initialFilterOptions[type];
      }

      setSelectedFilterOptions(newFilters);

      if (cb) cb(mapFilters(newFilters));
    } else {
      setSelectedFilterOptions(initialFilterOptions);
    }
  };

  const selectFilter = (type, value, cb) => {
    const currentFilters = selectedFilterOptions[type];
    const selectedFilter = filterOptions[type].find(
      (filter) => filter.label === value,
    );
    let newFilters;

    if (type === 'sort') {
      // sort is handled differently for being single-select
      newFilters = selectedFilter;
    } else if (findIndex(currentFilters, selectedFilter) === -1) {
      newFilters = [...currentFilters, selectedFilter];
    } else {
      newFilters = currentFilters.filter(
        (filter) => filter.label !== selectedFilter.label,
      );
    }

    newFilters = {...selectedFilterOptions, [type]: newFilters};

    setSelectedFilterOptions(newFilters);

    if (cb) {
      cb(mapFilters(newFilters));
    }
  };

  return (
    <SortFiltersContext.Provider
      value={{
        clearFilters,
        currentFilterCount:
          selectedFilterOptions.price.length +
          selectedFilterOptions.status.length +
          selectedFilterOptions.stores.length,
        currentSortFilters,
        filterOptions,
        initialFilters,
        initialFilterOptions,
        selectedFilterOptions,
        selectFilter,
        setCurrentSortFilters,
        setSelectedFilterOptions,
      }}>
      {children}
    </SortFiltersContext.Provider>
  );
};

SortFiltersContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
