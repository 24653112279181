import analyticsWrapper from '@xo-union/tk-analytics';
import {getProductBaseAnalytics} from './getBaseAnalytics';

const trackUniversalProductClick = (isMobile, application, product) => {
  const {name, storeName} = product;
  const analytics = getProductBaseAnalytics(isMobile, application, product);

  analyticsWrapper.track('Universal Registry Interaction', {
    ...analytics,
    name,
    selection: 'purchase',
    store: storeName,
    userDecisionArea: 'checkout modal',
  });
};

export default trackUniversalProductClick;
