export const safeParse = (objectString) => {
  if (!objectString || objectString.length === 0) {
    return {};
  }

  try {
    const data = JSON.parse(objectString);

    return data;
  } catch (err) {
    return {};
  }
};

export const convertToDashCase = (string) =>
  string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('-');

export const formatPrice = (rawPrice) => {
  const price = typeof rawPrice === 'string' ? parseFloat(rawPrice) : rawPrice;

  return price.toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
