import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Spinner from '@xo-union/tk-component-spinner';
import style from './index.scss';

const OverlaySpinner = ({isOpaque}) => (
  <div
    data-testid="loading-container"
    className={cx(style['overlay-spinner-container'], {
      [style['opaque-background']]: isOpaque,
    })}>
    <Spinner className={style['overlay-spinner']} />
  </div>
);

OverlaySpinner.propTypes = {
  isOpaque: PropTypes.bool,
};

OverlaySpinner.defaultProps = {
  isOpaque: false,
};

export default OverlaySpinner;
