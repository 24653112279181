import '@xo-union/tk-ui-essentials';
import React from 'react';
import PropTypes from 'prop-types';
import {compose} from '@xo-union/react-css-modules';
import {useFlagsStatus} from '@unleash/proxy-client-react';
import {
  Column,
  FullBleedContainer,
  TopLevelContainer,
  Row,
} from '@xo-union/tk-component-grid';
import ThinFooter, {FooterLink} from '@xo-union/tk-ui-thin-footer';
import CoupleSummary from '../../proptypes/CoupleSummary';
import CoupleInfo from './components/CoupleInfo';
import Header from './components/Header';
import ProductGrid from '../../components/ProductGrid';
import RegistryList from '../../components/RegistryList';
import OverlaySpinner from '../../components/OverlaySpinner';
import {APPLICATION_GVR, BASE_URL} from '../../constants';
import {useCoupleSummary} from '../../queries/couple';
import {shouldShowRegistries} from '../../utils/registry';
import style from './style.scss';
import './utils/gvrPeripheralSetup';

const footerStyles = compose({
  'full-bleed-container': style.footer,
});

const GVRView = ({
  coupleData,
  isMobile,
  initialItems,
  showProducts: showProductsOverride,
}) => {
  const {
    hasTransactionalRegistry,
    isHiddenProducts,
    memberUuid,
    coupleUuid,
    registries,
  } = coupleData;

  const {flagsReady} = useFlagsStatus();
  const {hasCoupleSummary} = useCoupleSummary(memberUuid || coupleUuid);

  if (!hasCoupleSummary || !flagsReady) return <OverlaySpinner />;

  const {items = [], stores = []} = initialItems;
  const showProducts =
    showProductsOverride || (!isHiddenProducts && items.length > 0);

  const showRegistries = shouldShowRegistries(
    registries,
    items,
    hasTransactionalRegistry,
  );

  return (
    <>
      <Header />

      <TopLevelContainer>
        <Row>
          <Column className={style.couple} xs="12">
            <CoupleInfo memberId={memberUuid || coupleUuid} />

            {showRegistries && (
              <RegistryList
                application={APPLICATION_GVR}
                className={style['registry-list']}
                isMobile={isMobile}
                memberId={memberUuid || coupleUuid}
              />
            )}
          </Column>
        </Row>
      </TopLevelContainer>

      {showProducts && (
        <ProductGrid
          application={APPLICATION_GVR}
          isMobile={isMobile}
          memberId={memberUuid}
          coupleId={coupleUuid}
          products={items}
          stores={stores}
        />
      )}

      <FullBleedContainer classes={footerStyles}>
        <ThinFooter
          copyright={{sinceYear: 2019, entity: 'TKWW Registry, LLC'}}
          data-testid="gvr-footer"
          links={
            <>
              <FooterLink href={`${BASE_URL}/registry/store/retail-terms`}>
                Retail terms
              </FooterLink>
              <FooterLink
                href={`${BASE_URL}/registry/store/product-recall-policy`}>
                Product recall
              </FooterLink>
            </>
          }
        />
      </FullBleedContainer>
    </>
  );
};

GVRView.defaultProps = {
  isMobile: false,
  showProducts: false,
};

GVRView.propTypes = {
  coupleData: CoupleSummary.type.isRequired,
  initialItems: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    stores: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isMobile: PropTypes.bool,
  showProducts: PropTypes.bool,
};

export default GVRView;
