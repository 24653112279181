// eslint-disable-next-line import/no-extraneous-dependencies
import 'whatwg-fetch';
import filterProducts from './filterProducts';
import {
  RIG_API_HOST,
  CASH_REGISTRY_TYPE,
  TRANSACTIONAL_PRODUCT,
  RETAILER_PRODUCT,
} from '../../../constants';

const REQUEST_LIMIT = 100;

const filterRegistries = (registries) => {
  const filter = (registry) =>
    !registry.hiddenCoupleSearch &&
    !registry.hiddenWws &&
    !registry.isDeleted &&
    registry.registryType !== CASH_REGISTRY_TYPE;

  return registries.filter(filter);
};

const fetchRIGItems = async (args) => {
  const {
    filters = {},
    page,
    registryIds = [],
    showCash = false,
    sort = [],
    memberId = null,
    showGiftCards = false,
  } = args;
  if (registryIds.length === 0 && !memberId) return {data: [], count: 0}; // We still require list of registries for non-member

  const queryParams = [`limit=${REQUEST_LIMIT}`];

  if (filters) {
    const filterQueryParams = Object.entries(filters).map(
      ([property, conditions]) => {
        let conditionsStr;
        if (property === 'fulfilled') {
          conditionsStr = conditions;
        } else if (property === 'priceRange') {
          conditionsStr = conditions
            .map(({min, max}) => `${min}-${max}`)
            .join(',');
        } else {
          conditionsStr = conditions
            .map((condition) => encodeURIComponent(condition))
            .join(',');
        }

        return `${property}=${conditionsStr}`;
      },
    );

    queryParams.push(...filterQueryParams);
  }

  if (memberId) {
    queryParams.push(`memberId=${memberId}`);
    queryParams.push(`showCash=${showCash}`);
    queryParams.push(`showGiftCards=${showGiftCards}`);
    queryParams.push(`showDisabled=false`);
  }

  if (page) queryParams.push(`offset=${page * REQUEST_LIMIT}`);

  if (memberId) {
    queryParams.push(`registryStatusFilter=active,visible`);
  } else {
    queryParams.push(`registryIds=${registryIds.join(',')}`);
  }

  if (sort && sort.length > 0) {
    const sortValues = sort
      .map(({property, direction}) => `${property}-${direction}`)
      .join(',');
    queryParams.push(`sort=${sortValues}`);
  }

  const response = await fetch(
    `${RIG_API_HOST}/guest/items?${queryParams.join('&')}`,
  );

  if (!response.ok) {
    throw new Error(
      JSON.stringify({
        message: 'Failed to fetch items',
        args,
        status: response.status,
        statusText: response.statusText,
      }),
    );
  }

  return response.json();
};

/*
  The API has a hard limit of 100 per request. Until the products-grid-component
  is refactored to handle API-based pagination and filtering, this is a temporary fix
  to ensure we're loading everything in a way that can be easily removed later.
*/
const fetchAllItems = async ({
  items = [],
  page = 0,
  registryIds = [],
  ...args
}) => {
  const {data, stores} = await fetchRIGItems({
    ...args,
    registryIds,
    page,
  });

  const allItems = [...items, ...data];

  if (data.length >= REQUEST_LIMIT) {
    return fetchAllItems({
      items: allItems,
      page: page + 1,
      registryIds,
      ...args,
    });
  }

  return {items: allItems, stores};
};

const fetchItems = async ({registries = [], ...args}) => {
  const registryIds = filterRegistries(registries).map(
    (registry) => registry.registryUuid,
  );
  const {items, stores} = await fetchAllItems({registryIds, ...args});

  const hasTKRSProducts = stores.some((s) => s.type === TRANSACTIONAL_PRODUCT);
  const hasAffiliateProducts = stores.some((s) => s.type === RETAILER_PRODUCT);

  return {
    items: filterProducts(items, registries),
    stores,
    hasTKRSProducts,
    hasAffiliateProducts,
  };
};

export default fetchItems;
