import React, {useContext, useState} from 'react';
import cx from 'classnames';
import {ProductImage} from '@tkww/registry-component-product-details';
import CoupleNote, {
  CoupleNoteBadge,
} from '@tkww/registry-component-couple-note';
import TopChoiceBadge from '../TopChoiceBadge';
import Product from '../../../../proptypes/Product';
import {
  AMOUNT_NEEDED_PURCHASED,
  AMOUNT_NEEDED_UNAVAILABLE,
  CASH_FUND,
  UNIVERSAL_REGISTRY_PRODUCT,
  ENV,
} from '../../../../constants';
import {useCoupleSummary} from '../../../../queries/couple';
import {AppContext} from '../../contexts/AppContext';
import {stopPropagation} from '../../../../utils/event';
import {getImage} from '../../../../utils/registryItem';
import getAmountNeeded from '../../utils/getAmountNeeded';
import style from './style.scss';

const getImageOptimizationQuery = (imageUrl = '') => {
  if (
    imageUrl.startsWith(
      `https://${ENV === 'qa' ? 'staging-' : ''}gifts.theknot.com`,
    )
  ) {
    return '?quality=85&height=315&width=315';
  }

  return '';
};

const ProductCardImage = ({product}) => {
  const {isMobile, memberId} = useContext(AppContext);
  const {data: {fianceFirstName, firstName} = {}} = useCoupleSummary(memberId);
  const [showCoupleNote, setShowCoupleNote] = useState(false);
  const {coupleNote, topChoice, type} = product;
  const {amountNeeded} = getAmountNeeded(product);
  const image = getImage(product);

  const onClick = (e, allowInDesktop = false) => {
    if (!allowInDesktop && !isMobile) return;

    setShowCoupleNote(!showCoupleNote);
    stopPropagation(e);
  };

  return (
    <div
      className={style['product-image-wrapper']}
      data-testid="product-image-wrapper">
      {!!coupleNote && showCoupleNote ? (
        <div
          className={style['couple-note-wrapper']}
          onMouseLeave={() => setShowCoupleNote(false)}
          data-testid="product-image-couple-note-wrapper">
          <CoupleNote
            className={style['couple-note-container']}
            coupleNote={coupleNote}
            fianceFirstName={fianceFirstName}
            firstName={firstName}
            onClick={onClick}
          />
        </div>
      ) : (
        <>
          {!!topChoice && (
            <TopChoiceBadge className={style['top-choice-badge']} />
          )}
          {!!coupleNote && (
            <CoupleNoteBadge
              containerClassName={style['couple-note-badge']}
              fianceFirstName={fianceFirstName}
              firstName={firstName}
              onClick={onClick}
              onMouseEnter={() => setShowCoupleNote(true)}
              onMouseLeave={() => setShowCoupleNote(false)}
            />
          )}
          <ProductImage
            className={cx({
              [style['product-image']]:
                amountNeeded === AMOUNT_NEEDED_PURCHASED ||
                amountNeeded === AMOUNT_NEEDED_UNAVAILABLE,
            })}
            image={`${image}${getImageOptimizationQuery(image)}`}
            imageBackgroundSize={
              type === CASH_FUND || type === UNIVERSAL_REGISTRY_PRODUCT
                ? 'cover'
                : 'contain'
            }
          />
        </>
      )}
    </div>
  );
};

ProductCardImage.propTypes = {
  product: Product.type.isRequired,
};

export default ProductCardImage;
