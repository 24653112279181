import analyticsWrapper from '@xo-union/tk-analytics';
import {wishlistProductAddedToCart} from '../../../../analytics';
import {getProductBaseAnalytics} from './getBaseAnalytics';
import mapProductForAnalytics from '../../../../analytics/helpers/mapProductForAnalytics';
import {getPrice} from '../../../../utils/registryItem';

const trackCashFund = (
  isMobile,
  application,
  product,
  selection,
  memberId,
  source = '',
  cartId,
) => {
  const {cashFundTemplateId, name: fundName, fundType, numRequested} = product;
  const quantity = document.querySelector('input')?.value;
  let giftingType;
  let fundAmount = (numRequested * getPrice(product)).toString();

  if (fundType === 'fixed') {
    giftingType = 'segmented';
  } else if (numRequested > 0) {
    giftingType = 'open with goal';
  } else {
    giftingType = 'open without goal';
    fundAmount = 'unlimited';
  }

  const analytics = getProductBaseAnalytics(
    isMobile,
    application,
    product,
    memberId,
    source,
  );

  analyticsWrapper.track('Cash Fund Interaction', {
    ...analytics,
    fundAmount,
    fundName,
    fundType: (cashFundTemplateId || 1) === 1 ? 'custom' : 'suggested',
    giftingType,
    interactionType: 'view fund details',
    memberId,
    selection,
    sourceFundId: cashFundTemplateId,
  });

  if (selection === 'check out') {
    wishlistProductAddedToCart({
      ...mapProductForAnalytics(product),
      coupon: undefined,
      quantity: Number(quantity) || undefined,
      list_id: 'guest-registry',
      cart_id: cartId,
      wishlist_id: memberId,
      wishlist_name: 'the Knot Wedding Registry',
    });
  }
};

export default trackCashFund;
