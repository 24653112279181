import PropTypes from 'prop-types';

export default {
  type: PropTypes.shape({
    fianceFirstName: PropTypes.string.isRequired,
    fianceLastName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    eventDate: PropTypes.string,
    hasTransactionalRegistry: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    isCashRegistryActive: PropTypes.bool.isRequired,
    isHiddenProducts: PropTypes.bool.isRequired,
    memberUuid: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    registries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    stripeAccountStatus: PropTypes.string,
    wws: PropTypes.shape({
      isHiddenWebsite: PropTypes.bool.isRequired,
      websiteUrl: PropTypes.string.isRequired,
    }),
  }),
};
