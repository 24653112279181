import {APPLICATION_WWS} from '../constants';
import isWeddingWebsite from './isWeddingWebsite';

const getWwsTempVersion = (application) => {
  let wwsTempVersion = null;

  if (isWeddingWebsite(application)) {
    wwsTempVersion =
      application === APPLICATION_WWS ? 'new WWS template' : 'old WWS template';
  }

  return wwsTempVersion;
};

export default getWwsTempVersion;
