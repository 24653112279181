import {useEffect, useState} from 'react';
import {useExperiment, useIsAssigned} from '@tkww/reg-flipper-sdk';
import {ENV, EXPERIMENTS} from '../../../../constants';

const IS_EXPERIMENT_ENABLED = true;

const useMuafExperiment = (integrationType) => {
  const [segmentAnonymousId, setAnonId] = useState(null);

  const experimentId = integrationType
    ? EXPERIMENTS.muaf[integrationType][ENV].experimentId
    : EXPERIMENTS.muaf[ENV].experimentId;
  const muafVariant = integrationType
    ? EXPERIMENTS.muaf[integrationType][ENV].variants.muafTKRS.id
    : EXPERIMENTS.muaf[ENV].variants.muafTKRS.id;
  const control = integrationType
    ? EXPERIMENTS.muaf[integrationType][ENV].variants.control.id
    : EXPERIMENTS.muaf[ENV].variants.control.id;

  useEffect(() => {
    if (!segmentAnonymousId) {
      window.analytics.ready(() => {
        const anonymousId = window.analytics.user().anonymousId();
        setAnonId(anonymousId);
      });
    }
  }, [segmentAnonymousId]);

  const args = {
    experimentId,
    bucketingId: segmentAnonymousId,
    skip: !IS_EXPERIMENT_ENABLED || !segmentAnonymousId,
    variantIfExcluded: control,
  };

  // returns 204 status code when rollout not in experiment
  const {isLoading} = useExperiment(args);
  const isMuafVariant = useIsAssigned(experimentId)(muafVariant);

  return {
    isLoading,
    isMuafVariant,
  };
};

export default useMuafExperiment;
