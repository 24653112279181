import {
  APPLICATION_GVR,
  APPLICATION_WWS,
  RETAILER_PRODUCT,
  TRANSACTIONAL_PRODUCT,
} from '../../../../constants';
import {isTransactionalItemAvailable} from '../../../../utils/registryItem';
import getWwsTempVersion from '../../../../utils/getWwsTempVersion';
import isWeddingWebsite from '../../../../utils/isWeddingWebsite';

const getBaseAnalytics = (isMobile, application, source) => ({
  platform: isMobile ? 'mobile' : 'web',
  product: isWeddingWebsite(application) ? APPLICATION_WWS : APPLICATION_GVR,
  source:
    source || (application === APPLICATION_GVR ? 'gvr' : 'wedding website'),
  wwsTempVersion: getWwsTempVersion(application),
});

export const getProductBaseAnalytics = (
  isMobile,
  application,
  product,
  memberId,
  source,
) => {
  const {
    isAvailable,
    fulfilled,
    name,
    offers = [],
    sku,
    topChoice,
    type,
  } = product;
  const analytics = getBaseAnalytics(isMobile, application, source);

  if (memberId && memberId.length) {
    analytics.memberId = memberId;
  }

  analytics.tag = topChoice ? 'topChoice' : 'none';
  if (name) analytics.item = name;
  if (sku && sku !== '') analytics.sku = sku;

  let productIsInStock = true;

  if (type === TRANSACTIONAL_PRODUCT) {
    productIsInStock = isTransactionalItemAvailable(type, offers);
  } else if (type === RETAILER_PRODUCT) {
    productIsInStock = isAvailable;
  }

  if (!productIsInStock) {
    analytics.giftStatus = 'out of stock';
  } else if (fulfilled) {
    analytics.giftStatus = 'fulfilled';
  } else {
    analytics.giftStatus = 'available';
  }

  return analytics;
};

export default getBaseAnalytics;
