import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {H2} from '@xo-union/tk-ui-typography/lib/components/Header/index.cjs';
import Icon from '@xo-union/tk-component-icons';
import {InlineAlert} from '@xo-union/tk-component-alerts';
import {Subtle, Subhead} from '@xo-union/tk-ui-typography';
import {Button, Link} from '@xo-union/tk-component-buttons';
import {Body1} from '@xo-union/tk-ui-typography/lib/components/Body/index.cjs';
import {
  getCartUrl,
  useGetCartId,
  useCartItemCount,
} from '@tkww/reg-shared-ui-cart-icon';
import {useFlag} from '@unleash/proxy-client-react';
import styles from './index.scss';
import {useCartItems} from '../../../../../../mutations/magento';
import {ProductsContext} from '../../../../contexts/ProductsContext';
import getRegistry from '../../../../utils/getRegistry';
import {getPrice, getStoreName} from '../../../../../../utils/registryItem';
import {AppContext} from '../../../../contexts/AppContext';
import {useCoupleSummary} from '../../../../../../queries/couple';
import trackProductCardClick from '../../../../utils/analytics/trackProductCardClick';
import {useMember} from '../../../../../../queries/member';
import useTrackWishListProductViewed from '../../../../../../analytics/trackWishListProductViewed';
import mapProduct from '../../../../../../analytics/helpers/mapProduct';
import {ModalContext} from '../../../../contexts/ModalContext';
import {DialogContext} from '../../../../contexts/DialogContext';
import MarkUnitAsFulfilledDialogContent from '../../../MarkUnitAsFulfilledDialogContent';
import PurchaseRemind from '../PurchaseRemind';
import useProductModalConfig from '../../../ProductModal/ProductModalConfig';
import trackMarkUnitAsFulfilledClick from '../../../../utils/analytics/trackMarkUnitAsFulfilledClick';
import useMuafExperiment from '../../../MarkUnitAsFulfilledButton/useMuafExperiment';
import {GIFT_INTEGRATION_TYPES} from '../../../../../../constants';

const CartAbandonmentAlert = () => {
  const {memberId} = useContext(AppContext);
  const {data: {fianceFirstName, firstName} = {}} = useCoupleSummary(memberId);
  const {data: cartId} = useGetCartId();
  const {data: cart} = useCartItems({cartId}) || [];
  const {data: cartItemCount = 0} = useCartItemCount({cartId});
  const {hasTKRSProducts, hasAffiliateProducts} = useContext(ProductsContext);

  const cartPath = getCartUrl({withRedirect: true});
  const shouldDisplayAlert =
    (hasTKRSProducts || hasAffiliateProducts) && !!cartItemCount;

  if (!shouldDisplayAlert) return null;

  return (
    <InlineAlert type="info" className={styles['cart-abandonment-alert']}>
      <div className={styles.bold}>
        You have gifts for {fianceFirstName} & {firstName} waiting in your cart!
      </div>{' '}
      Don&apos;t forget to{' '}
      <a
        onClick={() => {
          window.analytics.track({
            event: 'Registry Interaction',
            properties: {
              selection: 'complete your checkout',
              wishlist_id: memberId,
              source: 'affiliate modal',
              affiliation: 'Retailer',
              products: cart?.map((cartItem) =>
                mapProduct({
                  ...cartItem.product,
                  quantity: cartItem.quantity,
                  id: cartItem.id,
                }),
              ),
            },
          });
        }}
        href={cartPath}>
        complete your checkout
      </a>{' '}
      before you leave.
    </InlineAlert>
  );
};

const handleCloseMuafInstructions = (
  toggleModal,
  productModalConfig,
  setModalHeader,
) => {
  setModalHeader('');
  toggleModal({
    isVisible: true,
    props: productModalConfig.props,
    content: <AffiliateModal />,
  });
};

const MuafInstructions = ({
  firstName,
  fianceFirstName,
  storeName,
  productHref,
  handleClickGoToStore,
  handleClose,
}) => {
  return (
    <>
      <Body1 className={styles.instructions}>
        {firstName && fianceFirstName
          ? `Because you shopped for this gift at a different store, go to the couple’s registry at ${storeName} to mark it as purchased.
      Or, share the purchase update directly with the couple.
      This helps ${firstName} and ${fianceFirstName} easily track their gifts.`
          : `Because you shopped for this gift at a different store, go to the couple’s registry at ${storeName} to mark it as purchased.
      Or, share the purchase update directly with the couple.
      This helps the couple easily track their gifts.`}
      </Body1>
      <Body1 className={styles.instructions}>
        We update the couple&apos;s gifts status daily.
      </Body1>
      <Body1 className={styles['btn-container-instructions']}>
        <Link
          color="primary"
          href={productHref}
          onClick={handleClickGoToStore}
          size="lg"
          target="_blank">
          Go to {storeName}
          <Icon className={styles.icon} name="linkout" aria-hidden="true" />
        </Link>
        <Button color="tertiary" type="button" size="lg" onClick={handleClose}>
          Cancel
        </Button>
      </Body1>
    </>
  );
};

MuafInstructions.propTypes = {
  firstName: PropTypes.string.isRequired,
  fianceFirstName: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  productHref: PropTypes.string.isRequired,
  handleClickGoToStore: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const AffiliateModal = () => {
  const {memberId, isMobile, application} = useContext(AppContext);
  const {currentProduct} = useContext(ProductsContext);
  const {onClose, toggleModal, setModalHeader} = useContext(ModalContext);
  const {toggleDialog, onCloseDialog} = useContext(DialogContext);
  const productModalConfig = useProductModalConfig();
  const {data: {fianceFirstName, firstName, registries} = {}} =
    useCoupleSummary(memberId);
  const {data: member} = useMember();
  const isMuafFlagEnabled = useFlag('reg-marked-gift-as-purchased');
  const {isMuafVariant} = useMuafExperiment(
    GIFT_INTEGRATION_TYPES.AFFILIATE_RETAILER,
  );

  const isMuafButtonEnabled = isMuafFlagEnabled && isMuafVariant;
  const registry = getRegistry(currentProduct, registries);
  const storeName = getStoreName(currentProduct);
  const formattedPrice = getPrice(currentProduct).toLocaleString('en-US', {
    currency: 'USD',
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useTrackWishListProductViewed({
    memberId,
    member,
    product: currentProduct,
    index: currentProduct.position,
  });

  const onClickNotBuyButton = () => {
    onClose();
    toggleDialog({
      isVisible: true,
      content: (
        <MarkUnitAsFulfilledDialogContent
          headerText="Keep shopping"
          contentText={
            firstName && fianceFirstName
              ? `See ${firstName} and ${fianceFirstName}’s registry for more gift ideas. Remember to mark your gift as purchased to help the couple track their gifts.`
              : `See the couple’s registry for more gift ideas. Remember to mark your gift as purchased to help the couple track their gifts.`
          }
          footer={
            <Button onClick={onCloseDialog} block color="primary" size="lg">
              Got it
            </Button>
          }
        />
      ),
    });
  };

  const onClickDidBuyButton = () => {
    onClose();
    toggleDialog({
      isVisible: true,
      content: (
        <MarkUnitAsFulfilledDialogContent
          headerText="Your gift will be marked as purchased"
          contentText={
            firstName && fianceFirstName
              ? `If you bought this gift from ${storeName}, we'll mark it as purchased on ${firstName} and ${fianceFirstName}’s registry within a day.`
              : `If you bought this gift from ${storeName}, we'll mark it as purchased on the couple’s registry within a day.`
          }
          footer={
            <Button onClick={onCloseDialog} block color="primary" size="lg">
              Got it
            </Button>
          }
        />
      ),
    });
  };

  const handleTabFocus = () => {
    onCloseDialog();
    toggleModal({
      isVisible: true,
      props: productModalConfig.props,
      content: (
        <PurchaseRemind
          onClickNotBuyButton={onClickNotBuyButton}
          onClickDidBuyButton={onClickDidBuyButton}
        />
      ),
    });
  };

  const onClickContinueStore = () => {
    window.addEventListener('focus', handleTabFocus());
  };

  const handleClickGoToStore = (e, trackMuaf = false) => {
    setModalHeader('');
    onClickContinueStore();

    if (trackMuaf) {
      trackMarkUnitAsFulfilledClick.goToStoreEvent({
        isMobile,
        application,
        analytics: {
          coupleRegistryStore: currentProduct.store,
          name: currentProduct.name,
        },
      });
      return;
    }
    trackProductCardClick({
      application,
      isMobile,
      product: currentProduct,
      registry,
      memberId,
    });
  };

  const handleClickMuafInstructions = () => {
    setModalHeader('Mark this gift as purchased');
    trackMarkUnitAsFulfilledClick.shoppedAtDifferentStoreEvent({
      isMobile,
      application,
      analytics: {
        coupleRegistryStore: currentProduct.store,
        name: currentProduct.name,
      },
    });

    toggleModal({
      isVisible: true,
      props: {
        onClose: () =>
          handleCloseMuafInstructions(
            toggleModal,
            productModalConfig,
            setModalHeader,
          ),
      },
      content: (
        <MuafInstructions
          firstName={firstName}
          fianceFirstName={fianceFirstName}
          storeName={storeName}
          productHref={currentProduct.href}
          handleClickGoToStore={(e) => handleClickGoToStore(e, true)}
          handleClose={() =>
            handleCloseMuafInstructions(
              toggleModal,
              productModalConfig,
              setModalHeader,
            )
          }
        />
      ),
    });
  };

  return isMobile ? (
    <div data-testid="product-details-container">
      <CartAbandonmentAlert />

      {!!storeName && (
        <Subtle
          className={styles.brand}
          data-testid="product-details-brandname">
          {storeName}
        </Subtle>
      )}

      <Subhead data-testid="product-details-name" className={styles.product}>
        {' '}
        {currentProduct.name}
      </Subhead>

      <H2 className={styles.price} data-testid="product-details-price">
        {formattedPrice}
      </H2>

      <img
        className={styles.image}
        alt={`${currentProduct.name}`}
        src={currentProduct.imageUrls[0]}
      />

      <div className={styles['cart-alert-copy']}>
        {firstName && fianceFirstName
          ? `${firstName} & ${fianceFirstName} added this gift from their registry on ${storeName}. We’ll update the registry status within a day after your purchase to help keep the couple’s gift list current.`
          : `The couple added this gift from their registry on ${storeName}. We’ll update the registry status within a day after your purchase to help keep the couple’s gift list current.`}
      </div>
      <Body1 className={styles['btn-container']}>
        <Link
          className={styles.button}
          href={currentProduct.href}
          onClick={handleClickGoToStore}
          size="lg"
          color="secondary-alternative"
          aria-label={`Go to ${storeName} product page (opens in a new tab)`}
          target="_blank">
          {`Go to ${storeName}`}
          <Icon className={styles.icon} name="linkout" />
        </Link>
        {isMuafButtonEnabled && (
          <Button
            data-testid="muaf-instructions-button"
            className={styles.link}
            color="tertiary"
            type="button"
            size="lg"
            onClick={handleClickMuafInstructions}>
            Shopped at a different store?
          </Button>
        )}
      </Body1>
    </div>
  ) : (
    <>
      <CartAbandonmentAlert />

      <div className={styles.container} data-testid="product-details-container">
        <div className={styles['image-container']}>
          <img
            className={styles.image}
            alt={`${currentProduct.name}`}
            src={currentProduct.imageUrls[0]}
          />
        </div>
        <div className={styles.details}>
          {storeName && (
            <Subtle
              className={styles.brand}
              data-testid="product-details-brandName">
              {storeName}
            </Subtle>
          )}

          <Subhead
            data-testid="product-details-name"
            className={styles.product}>
            {currentProduct.name}
          </Subhead>

          <H2 className={styles.price} data-testid="product-details-price">
            {formattedPrice}
          </H2>
          <div className={styles.divider} />

          <div className={styles['cart-alert-copy']}>
            {firstName && fianceFirstName
              ? `${firstName} & ${fianceFirstName} added this gift from their registry on ${storeName}. We’ll update the registry status within a day after your purchase to help keep the couple’s gift list current.`
              : `The couple added this gift from their registry on ${storeName}. We’ll update the registry status within a day after your purchase to help keep the couple’s gift list current. `}
          </div>
          <Body1 className={styles['btn-container']}>
            <Link
              className={styles.button}
              href={currentProduct.href}
              onClick={handleClickGoToStore}
              size="lg"
              style={{marginTop: '1.25rem'}}
              color="secondary-alternative"
              aria-label={`Go to ${storeName} product page (opens in a new tab)`}
              target="_blank">
              {`Go to ${storeName}`}
              <Icon className={styles.icon} name="linkout" aria-hidden="true" />
            </Link>
            {isMuafButtonEnabled && (
              <Button
                data-testid="muaf-instructions-button"
                className={styles.link}
                color="tertiary"
                type="button"
                size="lg"
                onClick={handleClickMuafInstructions}>
                Shopped at a different store?
              </Button>
            )}
          </Body1>
        </div>
      </div>
    </>
  );
};

export default AffiliateModal;
