import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@xo-union/tk-component-icons';
import {Body2} from '@xo-union/tk-ui-typography';
import {
  INTERNAL_GIFT_CARD,
  SUBTYPE_EXPERIENCE,
  SUBTYPE_GIFT_CARD,
} from '../../../../constants';
import style from './style.scss';

const DIGITAL_GIFT_SUBTYPES = [SUBTYPE_EXPERIENCE, SUBTYPE_GIFT_CARD];

const DeliveryDetails = ({subtype, type}) => {
  const isDigitalProduct =
    DIGITAL_GIFT_SUBTYPES.includes(subtype) || type === INTERNAL_GIFT_CARD;

  return (
    <Body2 className={style['product-delivery-details']}>
      <Icon
        aria-hidden="true"
        name={isDigitalProduct ? 'email' : 'truck'}
        size="sm"
      />
      {isDigitalProduct ? 'Email Delivery' : 'Free Shipping & Returns'}
    </Body2>
  );
};

DeliveryDetails.defaultProps = {
  type: '',
  subtype: '',
};

DeliveryDetails.propTypes = {
  type: PropTypes.string,
  subtype: PropTypes.string,
};

export default DeliveryDetails;
