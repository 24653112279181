import PropTypes from 'prop-types';
import {
  TRANSACTIONAL_PRODUCT,
  CASH_FUND,
  CASH_FUND_TYPES,
  RETAILER_PRODUCT,
  UNIVERSAL_REGISTRY_PRODUCT,
} from '../constants';

/**
 * @typedef {Object} Product
 * @property {string} _id
 * @property {string} id
 * @property {string} coupleNote
 * @property {boolean} fulfilled
 * @property {string} imageUrl
 * @property {string[]} imageUrls
 * @property {string} memberId
 * @property {string} name
 * @property {number} numReceived
 * @property {number} numRequested
 * @property {number} priceCents
 * @property {number} productId
 */
const Product = {
  /** Values each registry item/product will have regardless of type */
  _id: PropTypes.string,
  id: PropTypes.string,
  coupleNote: PropTypes.string,
  fulfilled: PropTypes.bool,
  imageUrl: PropTypes.string,
  imageUrls: PropTypes.arrayOf(PropTypes.string),
  memberId: PropTypes.string,
  name: PropTypes.string,
  numReceived: PropTypes.number,
  numRequested: PropTypes.number,
  priceCents: PropTypes.number,
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  topChoice: PropTypes.string,
};

const TransactionalProduct = PropTypes.shape({
  ...Product,
  type: PropTypes.oneOf([TRANSACTIONAL_PRODUCT]),
  brandName: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  description: PropTypes.string,
  detailPageUrlKey: PropTypes.string,
  dimensions: PropTypes.shape({
    height: PropTypes.string,
    length: PropTypes.string,
    width: PropTypes.string,
  }),
  discontinued: PropTypes.bool,
  essentialInformation: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  galleryUrls: PropTypes.arrayOf(PropTypes.string),
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      offerId: PropTypes.number,
      quantity: PropTypes.number,
    }),
  ),
  primaryMaterial: PropTypes.string,
  shortDescription: PropTypes.string,
  sku: PropTypes.string,
  store: PropTypes.string,
  storeName: PropTypes.string,
  subtype: PropTypes.string,
  timestamp: PropTypes.shape({
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  whatsIncluded: PropTypes.arrayOf(PropTypes.string),
  denominationGifting: PropTypes.shape({
    percentFulfilled: PropTypes.number,
    amountFulfilled: PropTypes.number,
    amountRequested: PropTypes.number,
  }),
});

const CashFund = PropTypes.shape({
  ...Product,
  type: PropTypes.oneOf([CASH_FUND]),
  cashFundId: PropTypes.string,
  description: PropTypes.string,
  fundType: PropTypes.oneOf([CASH_FUND_TYPES.FIXED, CASH_FUND_TYPES.UNLIMITED]),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

const RetailerItem = PropTypes.shape({
  ...Product,
  type: PropTypes.oneOf([RETAILER_PRODUCT]),
  isAvailable: PropTypes.bool,
  isFulfilled: PropTypes.bool,
  retailerName: PropTypes.string,
  retailerSortOrder: PropTypes.number,
  store: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

const UniversalItem = PropTypes.shape({
  ...Product,
  type: PropTypes.oneOf([UNIVERSAL_REGISTRY_PRODUCT]),
  isAvailable: PropTypes.bool,
  isFulfilled: PropTypes.bool,
  deleted: PropTypes.bool,
  storeName: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

export default {
  type: PropTypes.oneOfType([
    TransactionalProduct,
    CashFund,
    RetailerItem,
    UniversalItem,
  ]),
};
