import {APPLICATION_WWS, GVR_HB_APPLICATION_NAME} from '../constants';

// Matches urls that end with /registry regardless of query strings
const urlPathEndsWithRegistryRegEx = new RegExp(
  /\/([^/]+\/registry\/?)(?:$|\?)/,
  'g',
);

const filterHoneybadgerNotices = (notice) => {
  const isGVR = notice?.context?.application === GVR_HB_APPLICATION_NAME;
  const isRegistryTabOfWWS =
    notice?.context?.application === APPLICATION_WWS &&
    urlPathEndsWithRegistryRegEx.test(notice.url);

  if (isGVR || isRegistryTabOfWWS) {
    return true;
  }

  return false;
};

export default filterHoneybadgerNotices;
