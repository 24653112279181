/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '@xo-union/tk-component-icons';
import style from './style.scss';

const Select = ({className, label, onChange, ...rest}) => (
  <div
    className={cx(className, style['select-container'])}
    data-testid="select-container">
    <select
      {...rest}
      className={style['input-valid']}
      data-testid="select"
      id={label}
      name={label}
      onChange={(event) => onChange(event.target.value)}
      required
    />
    <Icon data-testid="select-icon" name="caret_down" size="md" />
    <label data-testid="select-label" htmlFor={label}>
      {label}
    </label>
  </div>
);

Select.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Select.defaultProps = {
  className: '',
};

export default Select;
