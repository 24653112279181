const SORT_OPTION_RETAILERS = {
  label: 'Featured',
  value: {
    property: 'retailerSortOrder',
    direction: 'asc',
  },
};

const defaultSort = [
  {
    label: 'Price low to high',
    value: {
      property: 'priceCents',
      direction: 'asc',
    },
  },
  {
    label: 'Price high to low',
    value: {
      property: 'priceCents',
      direction: 'desc',
    },
  },
  SORT_OPTION_RETAILERS,
];

const getFilterOptions = (stores = []) => ({
  price: [
    {
      label: '$0-49',
      value: {
        min: 0,
        max: 4999,
      },
    },
    {
      label: '$50-99',
      value: {
        min: 5000,
        max: 9999,
      },
    },
    {
      label: '$100-149',
      value: {
        min: 10000,
        max: 14999,
      },
    },
    {
      label: '$150+',
      value: {
        min: 15000,
        max: '*',
      },
    },
  ],
  sort: defaultSort,
  status: [
    {
      label: 'Available',
      value: 'false',
    },
    {
      label: 'Purchased',
      value: 'true',
    },
  ],
  stores,
});

export const getInitialFilterOptions = (stores = []) => ({
  price: [],
  sort: SORT_OPTION_RETAILERS,
  status: [],
  stores,
});

export default getFilterOptions;
