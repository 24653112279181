import React, {createContext, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useMobileMedia} from '@xo-union/tk-ui-breakpoints';
import isWeddingWebsite from '../../../utils/isWeddingWebsite';
import {
  APPLICATION_GVR,
  APPLICATION_WWS,
  APPLICATION_WWS_OLD_TEMPLATE,
  SOURCE_AFFILIATE_ID_REGISTRY,
  SOURCE_AFFILIATE_ID_WWS,
} from '../../../constants';

export const AppContext = createContext({
  application: '',
  cartRedirectPath: '',
  initialProducts: [],
  initialStores: [],
  isMobile: undefined,
  memberId: '',
  scrollPosition: 0,
  scrollToLastProduct: () => {},
  setScrollPosition: () => {},
  setStores: () => {},
  sourceAffiliateId: '',
});

export const AppContextProvider = ({
  application,
  cartRedirectPath,
  children,
  initialProducts,
  initialStores,
  isMobile: isMobileInitial,
  memberId,
  coupleId,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const sourceAffiliateId = isWeddingWebsite(application)
    ? SOURCE_AFFILIATE_ID_WWS
    : SOURCE_AFFILIATE_ID_REGISTRY;
  const isMobileDevice = !!useMobileMedia().yes;
  const scrollToLastProduct = useCallback(() => {
    window.scrollTo(0, scrollPosition);
  }, [scrollPosition]);

  return (
    <AppContext.Provider
      value={{
        scrollPosition,
        application,
        cartRedirectPath,
        initialStores,
        initialProducts,
        isMobile: isMobileDevice || isMobileInitial,
        memberId: memberId && memberId.toLowerCase(),
        coupleId,
        scrollToLastProduct,
        setScrollPosition,
        sourceAffiliateId,
      }}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  application: PropTypes.oneOf([
    APPLICATION_GVR,
    APPLICATION_WWS,
    APPLICATION_WWS_OLD_TEMPLATE,
  ]).isRequired,
  cartRedirectPath: PropTypes.string,
  children: PropTypes.node.isRequired,
  initialProducts: PropTypes.arrayOf(PropTypes.shape({})),
  initialStores: PropTypes.arrayOf(PropTypes.shape({})),
  isMobile: PropTypes.bool,
  memberId: PropTypes.string,
  coupleId: PropTypes.string,
};

AppContextProvider.defaultProps = {
  cartRedirectPath: '',
  initialProducts: [],
  initialStores: [],
  isMobile: undefined,
  memberId: '',
  coupleId: '',
};
