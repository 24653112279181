import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {useMobileMedia} from '@xo-union/tk-ui-breakpoints';
import NonPartnerItem from '../NonPartnerItem';
import {
  APPLICATION_GVR,
  APPLICATION_WWS,
  APPLICATION_WWS_OLD_TEMPLATE,
  SOURCE_AFFILIATE_ID_REGISTRY,
  SOURCE_AFFILIATE_ID_WWS,
} from '../../constants';
import {
  getShownRegistries,
  sortRegistries,
  getShowCashRegistry,
} from '../../utils/registry';
import {useCoupleSummary} from '../../queries/couple';
import style from './style.scss';

const NonPartnerStores = ({
  application,
  className,
  isMobile: isMobileInitial,
  itemClassName,
  memberId,
  registries,
}) => {
  const {data: coupleSummary = {}} = useCoupleSummary(memberId);
  const showCashRegistry = getShowCashRegistry(coupleSummary);
  const isMobileDevice = !!useMobileMedia().yes;
  const sourceAffiliateId =
    application === APPLICATION_GVR
      ? SOURCE_AFFILIATE_ID_REGISTRY
      : SOURCE_AFFILIATE_ID_WWS;
  const filteredRegistries = getShownRegistries(registries, showCashRegistry);
  const registryList = sortRegistries(filteredRegistries);

  return (
    <div
      data-testid="non-partner-stores"
      className={cx(style['non-partner-stores-container'], className)}
      role="group">
      {registryList.map((registry) => (
        <NonPartnerItem
          application={application}
          className={itemClassName}
          isMobile={isMobileDevice || isMobileInitial}
          key={`registry-${registry.id}`}
          memberId={memberId}
          registry={registry}
          sourceAffiliateId={sourceAffiliateId}
        />
      ))}
    </div>
  );
};

NonPartnerStores.propTypes = {
  application: PropTypes.oneOf([
    APPLICATION_GVR,
    APPLICATION_WWS,
    APPLICATION_WWS_OLD_TEMPLATE,
  ]).isRequired,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  itemClassName: PropTypes.string,
  memberId: PropTypes.string,
  registries: PropTypes.arrayOf(
    PropTypes.shape({
      hiddenCoupleSearch: PropTypes.bool,
      hiddenWws: PropTypes.bool,
      id: PropTypes.string.isRequired,
      manualRegistryName: PropTypes.string,
      registryType: PropTypes.number.isRequired,
      retailer: PropTypes.shape({
        fullLogoImageUrl: PropTypes.string,
        id: PropTypes.number,
        isDeleted: PropTypes.bool,
        isPartner: PropTypes.bool,
        oneColSortOrder: PropTypes.number,
        retailerName: PropTypes.string,
      }),
    }),
  ).isRequired,
};

NonPartnerStores.defaultProps = {
  className: '',
  isMobile: undefined,
  itemClassName: '',
  memberId: '',
};

export default NonPartnerStores;
