import React, {useContext} from 'react';
import {Body1} from '@xo-union/tk-ui-typography';
import {MultiSelectFilter} from '../Filters';
import {SortFiltersContext} from '../../contexts/SortFiltersContext';
import style from './style.scss';

const SideFilters = () => {
  const {filterOptions} = useContext(SortFiltersContext);

  return (
    <div data-testid="side-filters-container">
      <div
        className={style['side-filters-status-group']}
        data-testid="side-filters-price-group">
        <Body1
          bold
          className={style['group-label']}
          data-testid="side-filters-price-label">
          Price
        </Body1>
        <MultiSelectFilter testId="side-filters" type="price" />
      </div>

      {filterOptions.stores.length > 0 && (
        <div
          className={style['side-filters-status-group']}
          data-testid="side-filters-stores-group">
          <Body1
            bold
            className={style['group-label']}
            data-testid="side-filters-stores-label">
            Store
          </Body1>
          <MultiSelectFilter testId="side-filters" type="stores" />
        </div>
      )}

      <div
        className={style['side-filters-status-group']}
        data-testid="side-filters-status-group">
        <Body1
          bold
          className={style['group-label']}
          data-testid="side-filters-status-label">
          Status
        </Body1>
        <MultiSelectFilter testId="side-filters" type="status" />
      </div>
    </div>
  );
};

export default SideFilters;
