import analyticsWrapper from '@xo-union/tk-analytics';
import isWeddingWebsite from '../../../../utils/isWeddingWebsite';
import {APPLICATION_GVR, APPLICATION_WWS} from '../../../../constants';

export default {
  /**
   * @analytics
   * {
   *    @source
   *    @coupleRegistryStore
   *    @name
   * }
   */
  clickToPopupForm: ({isMobile, application, analytics}) => {
    analyticsWrapper.track('Registry Interaction', {
      selection:
        'click-through to mark unit as fulfilled outside registry configuration',
      userDecisionArea: 'mark unit as fulfilled action',
      platform: isMobile ? 'mobile' : 'web',
      product: isWeddingWebsite(application)
        ? APPLICATION_WWS
        : APPLICATION_GVR,
      ...analytics,
    });
  },
  markUnitAsFulfilledSuccess: ({isMobile, application, analytics}) => {
    analyticsWrapper.track('Registry Interaction', {
      source: 'pdp modal',
      selection: 'mark unit as fulfilled outside registry configuration',
      userDecisionArea: 'mark unit as fulfilled action',
      platform: isMobile ? 'mobile' : 'web',
      product: isWeddingWebsite(application)
        ? APPLICATION_WWS
        : APPLICATION_GVR,
      ...analytics,
    });
  },
  shoppedAtDifferentStoreEvent: ({isMobile, application, analytics}) => {
    analyticsWrapper.track('Registry Interaction', {
      selection: 'muaf:popup_different_store',
      userDecisionArea: 'mark unit as fulfilled action',
      platform: isMobile ? 'mobile' : 'web',
      product: isWeddingWebsite(application)
        ? APPLICATION_WWS
        : APPLICATION_GVR,
      ...analytics,
    });
  },
  goToStoreEvent: ({isMobile, application, analytics}) => {
    analyticsWrapper.track('Registry Interaction', {
      selection: 'muaf:clicked to go to store to mark as purchased',
      userDecisionArea: 'mark unit as fulfilled action',
      platform: isMobile ? 'mobile' : 'web',
      product: isWeddingWebsite(application)
        ? APPLICATION_WWS
        : APPLICATION_GVR,
      ...analytics,
    });
  },
};
