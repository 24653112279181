import MembershipService from '@xo-union/sdk-membership';
import {useQuery} from '@tanstack/react-query';
import {getSessionToken} from '../utils/session';

const REFETCH_30_SECONDS = 1000 * 30;

export const membershipService = new MembershipService({
  clientOptions: {
    env:
      process.env.UNION_DEPLOYMENT_ENV === 'production' ? 'production' : 'qa',
    apiKey: process.env.UNION_MEMBERSHIP_API_KEY,
    version: 'v2',
  },
});

export const useMember = () => {
  return useQuery(
    ['member'],
    async () => {
      const sessionToken = await getSessionToken();
      if (!sessionToken) return null;

      return membershipService.getMember({
        token: sessionToken,
      });
    },
    {
      select: (data) => data?.body?.members || null,
      refetchInterval: REFETCH_30_SECONDS,
    },
  );
};
